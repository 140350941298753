import React from 'react';

const ExcelIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 25"
      fill='var(--fntClr)'
      height={24}
      width={24}
      {...props}
    >
      <g transform="translate(-1450 -389)">
        <g transform="translate(-3487 968)">
          <path
            d="M27.758,35.763V22.41H26.68c-2.769.5-5.531.963-8.31,1.443v12.63c2.734.483,5.5.966,8.255,1.466H27.75V35.763Zm-4.072-2.772a21.3,21.3,0,0,1-.906-2.266c-.25.733-.6,1.423-.878,2.141-.4,0-.79-.023-1.186-.038.463-.908.911-1.824,1.388-2.724-.405-.931-.853-1.841-1.271-2.764l1.2-.068c.268.705.563,1.4.785,2.126.25-.768.593-1.5.9-2.231q.613-.043,1.228-.075-.72,1.481-1.453,2.969c.5,1,1,2.014,1.5,3.024C24.554,33.051,24.121,33.024,23.686,32.991Z"
            transform="translate(4923 -596.41)"
          />
          <path
            d="M57.72,29.33v.958h1.914V31.7H57.72v.775h1.914V33.89H57.72v.775h1.914v1.411H57.72v.775h1.914v1.4H57.72v.82h1.914v1.368H57.72V41.4h6.609V29.33Zm5.151,11.121H60.089V39.083h2.782Zm0-2.189H60.089V36.849h2.782Zm0-2.189H60.089V34.663h2.782Zm0-2.189H60.089V32.474h2.782Zm0-2.189H60.089V30.288h2.782Z"
            transform="translate(4893.493 -601.599)"
          />
        </g>
      </g>
    </svg>
  );
}
 
export default ExcelIcon;