import React, { useEffect, useRef, useState } from 'react';
import Button from '../../../_Common/Button';
import { Translate } from '../../../../utils/lang/translate';
import GroupeForm from '../../../_Common/GroupeForm';
import { checkPhoneNumberValidity } from '../../../../utils/helpers';
import { Select } from '../../_Common';
import { alertActions, commonActions } from '../../../../actions';
import { connect } from 'react-redux';
import { CommuneInterface, wilayaType } from '../../../../types';
import PhoneIcon from '../../../../svgs/PhoneIcon';
import { Event } from "../../_Common";
import CustomSelect from '../../_Common/CustomSelect/CustomSelect';
import CustomBadge from './CustomBadge';
import CloseIcon from '../../../../svgs/CloseIcon';

type CustomerInfoType = {
  name: string,
  phone: string,
  wilaya: number | null,
  commune: number | null,
  second_phone: string | null
};

interface CustomerInfoError {
  name: string | null,
  phone: string | null,
  wilaya: string | null,
  commune: string | null,
  secondPhone: string | null
};

interface CustomerInfoFormProps {
  onFormSubmit: (data: CustomerInfoType) => void,
  customerInfo?: CustomerInfoType | null,
  active: boolean,
  GetingWilaya: "0" | "1" | "2",
  GetWilayas: (country_id: number) => void,
  dataWilaya: wilayaType[],
  GetigCommune: "0" | "1" | "2",
  DataCommune: CommuneInterface[],
  Getcommunes: (query: string, auth?: boolean) => void, 
  SendAlert: (code: string, text: string, action: string) => void,
  dtStoreinfo: any,
  onGoback: () => void
};

const CustomerInfoForm = ({
  onFormSubmit,
  customerInfo,
  active,
  DataCommune,
  GetWilayas,
  Getcommunes,
  GetigCommune,
  GetingWilaya,
  dataWilaya,
  SendAlert,
  dtStoreinfo,
  onGoback
}: CustomerInfoFormProps) => {

  const INITIAL_NAME_PHONE_ERROR_STATE: CustomerInfoError = {
    name: null,
    phone: null,
    wilaya: null,
    commune: null,
    secondPhone: null
  };

  const secondPhoneInputRef = useRef<HTMLInputElement | null>(null);

  const [customerName, setCustomerName] = useState("");
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState<string>("");
  const [hideInput, setHideInput] = useState<number>(10);
  const [secondPhone, setSecondPhone] = useState<string>("");
  const [hideInput2, setHideInput2] = useState<number>(10);
  const [customerInfoError, setCustomerInfoError] = useState<CustomerInfoError>(INITIAL_NAME_PHONE_ERROR_STATE);
  const [wilaya, setWilaya] = useState<number | null>(null);
  const [commune, setCommune] = useState<number | null>(null);
  const [showSecondPhone, setShowSecondPhone] = useState<boolean>(false);

  const disableSubmit: boolean = 
    customerInfoError.name !== null || 
    customerInfoError.phone !== null ||
    customerInfoError.wilaya !== null ||
    customerInfoError.commune !== null ||
    (showSecondPhone && customerInfoError.secondPhone !== null)
  ;

  const handleWilayaChange = (option: wilayaType, _: string) => {
    Event("CUSTOMER_INFO_FORM", "CHANGE_WILAYA", "CHANGE_EVENT");
    setWilaya(option?.[0] ?? null);
    if(option){
      Getcommunes(`?wilaya=${option[0]}`, true);
      if(customerInfoError.wilaya) setCustomerInfoError(prev => ({ ...prev, wilaya: null }));
    }
    if(commune) setCommune(null);
  };

  const handleCommuneChange = (option: CommuneInterface | null, _: string) => {
    Event("CUSTOMER_INFO_FORM", "CHANGE_COMMUNE", "CHANGE_EVENT");
    setCommune(option?.id ?? null);
    if(option && customerInfoError.commune){
      setCustomerInfoError(prev => ({ ...prev, commune: null }));
    }
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>, second?: boolean) => {
    Event(
      "CUSTOMER_INFO_FORM", 
      second ? "CHANGE_CUSTOMER_SECOND_PHONE_NUMBER" : "CHANGE_CUSTOMER_PHONE_NUMBER", 
      "CHANGE_EVENT"
    );

    const value = e.target.value;
    second ? setSecondPhone(value) : setCustomerPhoneNumber(value);

    const limitPlusDZ = 13;
    const limitPlusTN = 12;
    const limitDZ = 10;
    const StartNumberPhone = value.substring(0, 4);
    const StartNumberPhone0 = value.substring(0, 1);

    if (StartNumberPhone === "+213") {
      second ? setHideInput2(limitPlusDZ) : setHideInput(limitPlusDZ);
    }
    if (StartNumberPhone === "+216") {
      second ? setHideInput2(limitPlusTN) : setHideInput(limitPlusTN);
    }
    if (StartNumberPhone0 === "0") {
      second? setHideInput2(limitDZ) : setHideInput(limitDZ);
    }
    if(checkPhoneNumberValidity(value)){
      if(second && customerInfoError.secondPhone){
        setCustomerInfoError(prev => ({ ...prev, secondPhone: null }));
      }else if(!second && customerInfoError.phone) {
        setCustomerInfoError(prev => ({ ...prev, phone: null }));
      }
    }
  };

  const handleCustomerNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    
    Event("CUSTOMER_INFO_FORM", "CHANGE_CUSTOMER_NAME", "CHANGE_EVENT");
    const { value } = e.target;
    if(value !== "" && customerInfoError.name) setCustomerInfoError(prev => ({ ...prev, name: null }));
    setCustomerName(value);
  };

  const handleSubmit = () => {
    
    Event("CUSTOMER_INFO_FORM", "FORM_SUBMIT", "CLICK_EVENT");

    let new_error = {...customerInfoError};

    if(!wilaya) {
      new_error.wilaya = Translate("error", "reqfield");
    };

    if(!commune) {
      new_error.commune = Translate("error", "reqfield");
    };

    if(customerName === "") {
      new_error.name = Translate("error", "eo4");
    };

    if(!checkPhoneNumberValidity(customerPhoneNumber)) {
      new_error.phone = Translate("error", "invalidphone");
    };

    if(showSecondPhone && !checkPhoneNumberValidity(secondPhone)){
      new_error.secondPhone = Translate("error", "invalidphone");
    };

    if(
      Object.values(new_error)
      .every(value => !value)
    ){
      Event("CUSTOMER_INFO_FORM", "FORM_SUBMIT_SUCCESS", "CLICK_EVENT");
      onFormSubmit({
        name: customerName,
        phone: customerPhoneNumber,
        wilaya: wilaya,
        commune: commune,
        second_phone: showSecondPhone ? secondPhone : null
      });
    }else{
      Event("CUSTOMER_INFO_FORM", "FORM_SUBMIT_FAILURE", "CLICK_EVENT");
      setCustomerInfoError(new_error);
    }
  };

  useEffect(() => {

    if(active){
      if(!GetingWilaya || GetingWilaya === '2'){
        GetWilayas(dtStoreinfo.country.id);
      };
  
      if(customerInfo){
        setCustomerName(customerInfo.name);
        setCustomerPhoneNumber(customerInfo.phone);
        setWilaya(customerInfo.wilaya);
        Getcommunes(`?wilaya=${customerInfo.wilaya}`, true);
        setCommune(customerInfo.commune);
        if(customerInfo.second_phone){
          setShowSecondPhone(true);
          setSecondPhone(customerInfo.second_phone);
        }
      }
    }
  
  }, [customerInfo, active]);

  useEffect(() => {

    if(
      GetingWilaya === '2' || 
      GetigCommune === '2'
    ) SendAlert("50", "Failed to fetch wilaya/commune data!", "");

  }, [GetingWilaya, GetigCommune]);

  
  return (
    <form 
      className="InFlx Stclmnf" 
      style={{ gap: "30px", display: `${active ? "flex" : "none"}` }}
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <div className="InFlx Stclmnf" style={{ gap: "16px" }}>
        <h3 className="DlMg">{Translate("orders", "orderinfo")}</h3>
        <div 
          className="InFlx" 
          style={{ flexWrap: "wrap", gap: "20px 16px" }}
        >
          <GroupeForm
            id={"customername"}
            name={"customername"}
            placeholder={Translate("orders", "customername")}
            text={Translate("orders", "customername")}
            type={"text"}
            value={customerName}
            workfun={handleCustomerNameChange}
            error={customerInfoError.name}
            stclass={customerInfoError.name ? "borderError" : ""}
            style={{ flex: "1 300px" }}
          />
          <GroupeForm
            id={"customerphonenumber"}
            name={"customerphonenumber"}
            placeholder={Translate("orders", "customerphonenumber")}
            text={Translate("orders", "customerphonenumber")}
            type={"phone"}
            pattern="^(((\+216)?[1-9]\d{7})|((0?|(00|\+)?213)[56789]\d{8}))$"
            value={customerPhoneNumber}
            workfun={handlePhoneNumberChange}
            maxLength={hideInput}
            error={customerInfoError.phone}
            stclass={customerInfoError.phone ? "borderError" : ""}
            style={{ flex: "1 300px" }}
          />
        </div>
        {
          showSecondPhone
          ?
            <div 
              className="InFlx AlgnItm"
              style={{ gap: "20px 16px" }}
            >
              <GroupeForm
                forwardedRef={secondPhoneInputRef}
                id={"customer-second-phone"}
                name={"customer-second-phone"}
                placeholder={Translate("orders", "customersecondphone")}
                text={Translate("orders", "customersecondphone")}
                type={"phone"}
                pattern="^(((\+216)?[1-9]\d{7})|((0?|(00|\+)?213)[56789]\d{8}))$"
                value={secondPhone}
                workfun={(e: React.ChangeEvent<HTMLInputElement>) => handlePhoneNumberChange(e, true)}
                maxLength={hideInput2}
                error={customerInfoError.secondPhone}
                stclass={customerInfoError.secondPhone ? "borderError" : ""}
                style={{ flex: "1 300px" }}
              />
              <div
                onClick={() => setShowSecondPhone(false)} 
                className="InFlx CrsPoi"
                style={{ flex: "1 300px" }}
              >
                <CloseIcon/>
              </div>
            </div>
          :
            <div 
              className="CrsPoi StBle InFlx" 
              onClick={() =>{ 
                Event("CUSTOMER_INFO_FORM", "TOGGLE_CUSTOMER_SECOND_PHONE_NUMBER", "CLICK_EVENT");
                setShowSecondPhone(true);
              }}
            >
              <span style={{ margin: "2.5px 5px 0 5px" }}>
                <PhoneIcon height={16} width={16} fill='#3498db'/>
              </span>
              <span>{Translate("orders", "addsecondphonenumber")}</span>
            </div>
        }
        <div
          className="InFlx" 
          style={{ flexWrap: "wrap", gap: "20px 16px" }}
        >
          <Select
            label={Translate("orders", "city")}
            placholder={Translate("orders", "citySearch")}
            search={true}
            Options={GetingWilaya !== '1' ? [] : dataWilaya}
            fieldShow={1}
            name="city"
            loading={GetingWilaya === '0'}
            value={
              (dataWilaya ?? []).find(
                w => w[0] === wilaya
              ) ?? null
            }
            onChange={handleWilayaChange}
            disabled={GetingWilaya === '0' || GetingWilaya === '2'}
            error={customerInfoError.wilaya}
            containerClass={
              customerInfoError.wilaya
              ? "borderError" : ""
            }
            style={{ flex: "1 300px" }}
          />
          <CustomSelect
            label={Translate("orders", "district")}
            placeholder={Translate("orders", "districtSearch")}
            withSearch={true}
            options={GetigCommune !== '1' ? [] : DataCommune}
            loading={GetingWilaya === '0' || GetigCommune === '0'}
            field2Show={"name"}
            value={
              GetigCommune === '1' 
              ? DataCommune.find(item => item.id === commune) as CommuneInterface 
              : null
            }
            handler={handleCommuneChange}
            error={customerInfoError.commune}
            containerStyles={{ flex: "1 300px" }}
            maxDropHeight="200px"
            renderOption={(option) => <CommuneItem commune={option}/>}
            notice={Translate("orders", "homedeliveryavailable")}
          />
        </div>
      </div>
      <div className="FlWd" style={{ height: "1px", backgroundColor: "var(--fntClr)", opacity: 0.2 }}/>
      <div className="InFlx AlgnItm JstfCnt" style={{ gap: "30px" }}>
        <div style={{ width: "300px" }}>
          <Button
            gray={true}
            disabled={false} 
            BtnText={Translate("importorder", "goback")} 
            style={{ padding: "10px 0" }}
            type="button"
            onClick={() => {
              Event("CUSTOMER_INFO_FORM", "GO_BACK_BUTTON_CLICK", "CLICK_EVENT");
              onGoback();
            }}
          />
        </div>
        <div style={{ width: "300px" }}>
          <Button
            gray={disableSubmit}
            disabled={disableSubmit} 
            type="submit"
            BtnText={Translate("exchanges", "next")} 
            style={{ padding: "10px 0" }} 
          />
        </div>
      </div>
    </form>
  );
};

const actionCreators = {
  SendAlert: alertActions.SendAlert,
  GetWilayas: commonActions.Getwilayas,
  Getcommunes: commonActions.Getcommunes,
};

const mapState = (state: any) => {

  const { dtStoreinfo } = state.user;

  const {
    GetingWilaya,
    dataWilaya,
    GetigCommune,
    DataCommune,
  } = state.common;

  return {
    dtStoreinfo,
    GetingWilaya,
    dataWilaya,
    GetigCommune,
    DataCommune,
  };
};

const connectedCustomerInfoForm = connect(mapState, actionCreators)(CustomerInfoForm);
 
export { connectedCustomerInfoForm as CustomerInfoForm };

const CommuneItem = ({ commune }: {commune: CommuneInterface}) => (
  <div className="InFlx AlgnItm spcBtwn">
    <p className="DlMg">{commune.name}</p>
    <div className="InFlx AlgnItm" style={{ gap: "10px" }}>
      {
        commune.is_sd 
        && 
        <CustomBadge
          borderColor="#1d97fe"
          text={Translate("orders", "stopdesk")}
          bgColor="#1d97fe26"
        /> 
      }
      { 
        commune.is_pp 
        && 
        <CustomBadge 
          text={Translate("orders", "pickuppoint")} 
          bgColor="#e7780026" 
          borderColor="#e77800"
        /> 
      }
    </div>
  </div>
);