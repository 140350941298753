import React, { ChangeEvent, useState } from 'react';

interface CheckboxListProps<T, K extends keyof T> {
  withSearch?: boolean,
  placeholder?: string,
  options: T[],
  field2Show: K,
  fieldValue: K,
  checkedValues: T[K][], 
  onItemCheck: (value: T[K]) => void
};

const CheckboxList = <T, K extends keyof T>({
  withSearch,
  placeholder,
  field2Show,
  fieldValue,
  options,
  checkedValues,
  onItemCheck
}: CheckboxListProps<T, K>) => {

  const [search, setSearch] = useState<string>("");
  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };
  const onCheck = (value: T[K]) => onItemCheck(value);

  const showedOptions = options.filter(
    option => {
      return checkedValues.includes(option[fieldValue]) || 
      (search.length >= 2 && (option[field2Show] as string).toLowerCase().indexOf(search.toLowerCase()) !== -1);
    }
  );

  return (
    <div 
      className="InFlx Stclmnf" 
      style={{ gap: "5px" }}
    >
      {
        withSearch
        &&
        <input 
          type='text'
          value={search}
          onChange={onSearchChange}
          placeholder={placeholder}
          className="StBrdRd Fntcl StSizLn FrInp FntfMin lytwdp2p"
          style={{ height: "40px" }}
        />
      }
      <div 
        className="InFlx Stclmnf stbd2 HidScrolStil StPdsml" 
        style={{ maxHeight: "250px", gap: "8px" }}
      >
        {
          showedOptions.map((option, index) => (
            <div 
              key={index}
              className="InFlx flxDrc AlgnItm"
            >
              <input
                type="checkbox"
                onChange={() => onCheck(option[fieldValue])}
                value={option[fieldValue] as string | number}
                id={"CheckboxItem_" + field2Show.toString() + index}
                checked={checkedValues.includes(option[fieldValue])}
              />
              <label
                htmlFor={"CheckboxItem_" + field2Show.toString() + index}
              >
                {option[field2Show] as string}
              </label>
            </div>
          ))
        }
      </div>
    </div>
  );
}
 
export default CheckboxList;