import React from 'react';

const PickupIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="var(--fntClr)"
      viewBox="0 0 24 24"
      height={24}
      width={24}
      enableBackground="new 0 0 24 24"
    >
      <g>
        <g>
          <g>
            <path d="m4.25 8.001c-.413 0-.75-.336-.75-.75s.337-.75.75-.75.75.337.75.75-.337.75-.75.75z" />
          </g>
        </g>
      </g>
      <g>
        <g>
          <g>
            <path d="m8.776 8.001c-.413 0-.75-.336-.75-.75s.337-.75.75-.75.75.337.75.75-.337.75-.75.75z" />
          </g>
        </g>
      </g>
      <g>
        <g>
          <g>
            <path d="m6.506 11.005c-.675 0-1.31-.263-1.786-.741-.195-.195-.195-.512 0-.707.195-.194.512-.195.707.001.576.577 1.582.577 2.158 0 .195-.196.512-.195.707-.001.195.195.195.512 0 .707-.477.478-1.111.741-1.786.741z" />
          </g>
        </g>
      </g>
      <g>
        <g>
          <g>
            <path d="m.501 6.498c-.153 0-.304-.07-.402-.202-.163-.221-.119-.533.102-.697.087-.065 2.191-1.602 6.305-1.602s6.207 1.537 6.295 1.603c.22.166.264.477.1.698-.165.221-.477.268-.699.103-.019-.014-1.942-1.403-5.695-1.403-3.754 0-5.689 1.39-5.708 1.404-.091.064-.195.096-.298.096z" />
          </g>
        </g>
      </g>
      <g>
        <g>
          <g>
            <path d="m6.506 13.01c-1.634 0-2.996-.557-4.05-1.656-1.225-1.274-1.956-3.268-1.956-5.331 0-.042.005-.083.015-.122l.724-3.28c.208-.939.946-1.685 1.883-1.9l2.826-.648c.363-.084.751-.084 1.118 0l2.814.648c.937.216 1.675.961 1.881 1.9l.728 3.294c.007.036.011.072.011.108v.151c0 1.53-.511 3.694-1.943 5.181-1.059 1.098-2.422 1.655-4.051 1.655zm-5.006-6.934c.014 1.406.46 3.318 1.677 4.586.858.895 1.979 1.348 3.329 1.348 1.348 0 2.468-.454 3.33-1.349 1.226-1.273 1.664-3.153 1.664-4.487v-.096l-.716-3.241c-.124-.564-.567-1.012-1.129-1.141l-2.814-.648c-.218-.05-.451-.05-.671 0l-2.825.648c-.562.129-1.005.577-1.13 1.141z" />
          </g>
        </g>
      </g>
      <g>
        <g>
          <g>
            <path d="m5.388 22h-2.888c-.276 0-.5-.224-.5-.5v-8c0-.276.224-.5.5-.5s.5.224.5.5v7.5h2.388c.276 0 .5.224.5.5s-.224.5-.5.5z" />
          </g>
        </g>
      </g>
      <g>
        <g>
          <g>
            <path d="m16.5 22h-7c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h6.5v-10h-2.5c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h3c.276 0 .5.224.5.5v11c0 .276-.224.5-.5.5z" />
          </g>
        </g>
      </g>
      <g>
        <g>
          <g>
            <path d="m23.5 22h-2c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h1.5v-3.48c0-1.638-1.332-2.97-2.97-2.97h-3.03v6.45h.5c.276 0 .5.224.5.5s-.224.5-.5.5h-1c-.276 0-.5-.224-.5-.5v-7.45c0-.276.224-.5.5-.5h3.53c2.188 0 3.97 1.781 3.97 3.97v3.98c0 .276-.224.5-.5.5z" />
          </g>
        </g>
      </g>
      <g>
        <g>
          <g>
            <path d="m7.5 24c-1.379 0-2.5-1.122-2.5-2.5s1.121-2.5 2.5-2.5 2.5 1.122 2.5 2.5-1.121 2.5-2.5 2.5zm0-4c-.827 0-1.5.673-1.5 1.5s.673 1.5 1.5 1.5 1.5-.673 1.5-1.5-.673-1.5-1.5-1.5z" />
          </g>
        </g>
      </g>
      <g>
        <g>
          <g>
            <path d="m19.5 24c-1.379 0-2.5-1.122-2.5-2.5s1.121-2.5 2.5-2.5 2.5 1.122 2.5 2.5-1.121 2.5-2.5 2.5zm0-4c-.827 0-1.5.673-1.5 1.5s.673 1.5 1.5 1.5 1.5-.673 1.5-1.5-.673-1.5-1.5-1.5z" />
          </g>
        </g>
      </g>
    </svg>
  );
}
 
export default PickupIcon;