import React from 'react';

const ArrowIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      fill="var(--fntClr)"
      {...props}
    >
      <g transform="translate(-350 -439)">
        <path
          d="M170.41-99,175-94.42,179.59-99,181-97.59l-6,6-6-6Z"
          transform="translate(190 549)"
        />
      </g>
    </svg>
  );
}
 
export default ArrowIcon;