import React, { FormEvent, useEffect, useRef, useState } from 'react';
import TabShow from '../../_Common/TabShow';
import useClickOutside from '../../../../hooks/useClickOutside';
import CloseIcon from '../../../../svgs/CloseIcon';
import { Translate } from '../../../../utils/lang/translate';
import { connect } from 'react-redux';
import { alertActions, commonActions, pickupsActions } from '../../../../actions';
import { OrderPickup, RequestStatus, VehicleType } from '../../../../types';
import IconButton from '../../_Common/IconButton';
import ToggleSwitch from '../../../_Common/ToggleSwitch';
import GroupeForm from '../../../_Common/GroupeForm';
import CustomSelect from '../../_Common/CustomSelect/CustomSelect';
import { checkPhoneNumberValidity } from '../../../../utils/helpers';

type PickupInfos = {
  pickup_address: string | null,
  provider_phone: string | null,
  car_type: number | null
}

type PickupErrorType = {
  pickup_address: string | null,
  provider_phone: string | null,
  car_type: string | null
};

type PickupData = {
  store_id: string,
  commune: number,
  pickup_address: string,
  orders: string[]
};

interface CreatePickupModalProps {
  selected_orders: string[],
  ClearPickupsStore: () => void,
  GetPickups: (filter: string, type?: "starter" | "premium", persistFilter?: boolean) => void;
  SendAlert: (code: string, text: string, action: string) => void;
  AddPickup: (pickup_data: PickupData, type?: "starter" | "premium") => void;
  UpdatePickup: (pickup_data: PickupData, pickup_id: string) => void;
  GetVehiculeTypes: () => void;
  PickupGetting: RequestStatus;
  PickupAdded: RequestStatus;
  PickupUpdating: RequestStatus;
  GettingTypes: RequestStatus;
  onClose: () => void;
  PickupsList: {
    ressuc: {
      count: number,
      next: string,
      previous: string,
      results: OrderPickup[]
    },
    filter: string
  };
  VehiculesData: {
    list: VehicleType[]
  };
  dtStoreinfo: any
};

const CreatePickupModal = ({
  onClose,
  AddPickup,
  GetPickups,
  GetVehiculeTypes,
  GettingTypes,
  PickupAdded,
  PickupGetting,
  PickupUpdating,
  UpdatePickup,
  PickupsList,
  VehiculesData,
  dtStoreinfo,
  selected_orders,
  SendAlert,
  ClearPickupsStore
}: CreatePickupModalProps) => {

  const INITIAL_STATE = {
    pickup_address: dtStoreinfo?.address ?? null,
    provider_phone: dtStoreinfo?.phone ?? null,
    car_type: 2
  };

  const INITIAL_ERROR_STATE = {
    pickup_address: null,
    provider_phone: null,
    car_type: null
  };

  const modalRef = useRef<HTMLDivElement | null>(null);

  const [editPickup, setEditPickup] = useState<boolean>(false);

  const [pickupInfos, setPickupInfos] = useState<PickupInfos>(INITIAL_STATE);
  const [error, setError] = useState<PickupErrorType>(INITIAL_ERROR_STATE);

  const [hidePhoneInput, setHidePhoneInput] = useState<number>(10);

  const already_pending_pickups: boolean = PickupGetting === '2' && PickupsList.ressuc.results.length > 0;

  const handleProviderPhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    
    const value = e.target.value;
    setPickupInfos(prev => ({ ...prev, provider_phone: value }));

    const limitPlusDZ = 13;
    const limitPlusTN = 12;
    const limitDZ = 10;
    const StartNumberPhone = value.substring(0, 4);
    const StartNumberPhone0 = value.substring(0, 1);

    if (StartNumberPhone === "+213") {
      setHidePhoneInput(limitPlusDZ);
    }
    if (StartNumberPhone === "+216") {
      setHidePhoneInput(limitPlusTN);
    }
    if (StartNumberPhone0 === "0") {
      setHidePhoneInput(limitDZ);
    }

    if(checkPhoneNumberValidity(value)){
      if(error.provider_phone){
        setError(prev => ({ ...prev, provider_phone: null }));
      }
    }

  };

  const handlePickupAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if(error.pickup_address && value !== "") setError(prev => ({ ...prev, pickup_address: null }));
    setPickupInfos(prev => ({ ...prev, pickup_address: value }));  
  };

  const handleSubmit = (callback: () => void) => {

    let new_error = { ...error };

    if(!pickupInfos.car_type){
      new_error.car_type = Translate("error", "reqfield");
    }
    if(!pickupInfos.pickup_address){
      new_error.pickup_address = Translate("error", "reqfield");
    }
    if(!pickupInfos.provider_phone){
      new_error.provider_phone = Translate("error", "reqfield");
    }
    if(!checkPhoneNumberValidity(pickupInfos.provider_phone ?? "")){
      new_error.provider_phone = Translate("error", "invalidphone");
    }
    if(Object.values(new_error).every(value => !value)){
      // submit form
      callback();
    }else {
      setError({ ...new_error })
      setEditPickup(true);
    }
  }

  useClickOutside(modalRef, onClose);

  useEffect(() => {
    // get pending pickups
    GetPickups("?status=5");
    //get vehicle types
    GetVehiculeTypes();
  }, []);

  useEffect(() => {
    if(PickupAdded === '2') {
      SendAlert("41", "Successfully created a new pickup request", "");
      ClearPickupsStore();
      onClose();
    }
    else if(PickupAdded === '3') SendAlert("50", "Failed to create pickup request", "");
  }, [PickupAdded]);

  useEffect(() => {
    if(PickupUpdating === '2') {
      SendAlert("41", "Successfully updated pickup request", "");
      ClearPickupsStore();
      onClose();
    }
    else if(PickupAdded === '3') SendAlert("50", "Failed to update pickup request", "");
  }, [PickupUpdating]);

  return (
    <TabShow
      darkBg
      noReturn
      noPadding={true}
      style={{
        borderRadius: "8px",
        backgroundColor: "var(--defcl)",
        width: "fit-content"
      }}
    >
      <div 
        ref={modalRef} 
        className="InFlx Stclmnf" 
        style={{ 
          paddingTop: "10px", 
          paddingBottom: "10px", 
          gap: "10px", 
          minWidth: "fit-content" 
        }}
      >
        <div className="InFlx spcBtwn AlgnItm" style={{ padding: "5px 15px" }}>
          <div className="add-team-member-modal-header-text">
            {Translate("pickups", "confirmation")}
          </div>
          <div
            onClick={onClose} 
            className="InFlx AlgnItm sb4R CrsPoi Gray" 
            style={{ padding: "8px" }}
          >
            <CloseIcon/>
          </div>
        </div>
        <div className="add-staff-member-modal-divider"/>
        <div className="InFlx Stclmnf" style={{ gap: "20px", padding: "5px 15px" }}>
          <div className="InFlx Stclmnf" style={{ gap: "10px" }}>
            <p className="DlMg TxtSt StSizLn">
              {Translate("pickups", "aresure")}
            </p>
            <p className="DlMg StSizLn">
              {`${selected_orders.length} ${Translate("pickups", "ordersselected")}`}
            </p>
          </div>
          <div className="InFlx AlgnItm spcBtwn">
            <p className="DlMg TxtSt StSizLn">
              {Translate("pickups", "usedefaultparams")}
            </p>
            <ToggleSwitch
              isOn={editPickup}
              toggleHandler={() => setEditPickup(prev => !prev)}
            />
          </div>
          {
            editPickup
            &&
            <div className="InFlx Stclmnf" style={{ gap: "10px" }}>
              <div className="InFlx flex-wrap" style={{ gap: "10px" }}>
                <GroupeForm
                  id={"pickup_address"}
                  name={"pickup_address"}
                  placeholder={Translate("pickups", "adress")}
                  text={Translate("pickups", "adress")}
                  type={"text"}
                  value={pickupInfos.pickup_address}
                  workfun={handlePickupAddressChange}
                  error={error.pickup_address}
                  style={{ flex: "1 300px" }}
                  stclass={error.pickup_address ? "borderError" : ""}
                />
                <GroupeForm
                  id={"providerphonenumber"}
                  name={"providerphonenumber"}
                  placeholder={Translate("auths", "phone")}
                  text={Translate("auths", "phone")}
                  type={"phone"}
                  pattern={"^(((\+216)?[1-9]\d{7})|((0?|(00|\+)?213)[56789]\d{8}))$"}
                  value={pickupInfos.provider_phone}
                  workfun={handleProviderPhoneChange}
                  maxLength={hidePhoneInput}
                  error={error.provider_phone}
                  style={{ flex: "1 300px" }}
                  stclass={error.provider_phone ? "borderError" : ""}
                />
              </div>
              <div className="InFlx Stclmnf" style={{ gap: "10px" }}>
                <CustomSelect
                  withSearch
                  loading={GettingTypes === '1'}
                  options={GettingTypes === '2' ? VehiculesData.list : []}
                  field2Show={"name"}
                  maxDropHeight={'200px'}
                  handler={(new_car_type) => {
                    if(new_car_type){
                      setPickupInfos(prev => ({ ...prev, car_type: new_car_type.id }));
                      if(error.car_type) { 
                        setError(prev => ({ ...prev, car_type: null })); 
                      }
                    }else {
                      setPickupInfos(prev => ({ ...prev, car_type: null }));
                    }
                  }}
                  label={Translate("pickups","suitablevehicule")}
                  placeholder={Translate("pickups","suitablevehicule")}
                  value={VehiculesData?.list?.find(item => item.id === pickupInfos.car_type) ?? null}
                  error={error.car_type}
                />
              </div>
            </div>
          }
          {
            already_pending_pickups
            &&
            <p className="DlMg Bgstatus_11" style={{ fontSize: "16px" }}>
              {Translate("pickups", "alreadypending")}
            </p>
          }
        </div>
        <div className="InFlx JstfCnt" style={{ gap: "20px", padding: "5px 15px" }}>
          {
            already_pending_pickups
            &&
            <IconButton
              clickHandler={() => handleSubmit(
                () => UpdatePickup({ 
                  store_id: dtStoreinfo.id, 
                  pickup_address: pickupInfos.pickup_address ?? "", 
                  commune: dtStoreinfo.commune,
                  orders: [
                    ...PickupsList.ressuc.results[0].orders.map(order => order.id), 
                    ...selected_orders
                  ] 
                }, PickupsList.ressuc.results[0].id)
              )}
              label={Translate("pickups", "yesappend")}
              className="status_41"
            />
          }
          <IconButton
            clickHandler={() => handleSubmit(
              () => AddPickup({ 
                store_id: dtStoreinfo.id, 
                pickup_address: pickupInfos.pickup_address ?? "", 
                commune: dtStoreinfo.commune,
                orders: [...selected_orders] 
              })
            )}
            label={
              already_pending_pickups
              ? Translate("pickups", "inseparate")
              : Translate("orders", "yes")
            }
            className="BdgBlcl"
          />
          <IconButton
            clickHandler={onClose}
            label={Translate("profile", "cancel")}
            className="TxDng"
          />
        </div>
      </div>
    </TabShow>
  )
}

function mapState(state: any) {
  
  const { dtStoreinfo } = state.user;

  const {
    PickupGetting,
    PickupsList,
    PickupAdded,
    dataAddPickup,
    PickupUpdating,
    DataUpdatePickup,
  } = state.pickups;

  const {
    GettingTypes,
    VehiculesData,
  } = state.common;

  return {
    PickupGetting,
    PickupsList,
    PickupAdded,
    dataAddPickup,
    PickupUpdating,
    DataUpdatePickup,
    GettingTypes,
    VehiculesData,
    dtStoreinfo
  }
};

const actionCreators = {
  GetPickups: pickupsActions.GetPickups,
  UpdatePickup: pickupsActions.UpdatePickup,
  GetVehiculeTypes: commonActions.GetVehiculeTypes,
  AddPickup: pickupsActions.AddPickup,
  SendAlert: alertActions.SendAlert,
  ClearPickupsStore: pickupsActions.ClearPickupsStore
};

const connectedCreatePickupModal = connect(mapState, actionCreators)(CreatePickupModal);

export { connectedCreatePickupModal as CreatePickupModal };