import React from 'react';

const PrintIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="var(--fntClr)"
      viewBox="0 0 30 30"
      {...props}
    >
      <g transform="translate(-5248 -3746)">
        <path
          d="M1062-339h-14a3,3,0,0,0-3,3v6h4v4h12v-4h4v-6A3,3,0,0,0,1062-339Zm-3,11h-8v-5h8Zm3-7a1,1,0,0,1-1-1,1,1,0,0,1,1-1,1,1,0,0,1,1,1A1,1,0,0,1,1062-335Zm-1-9h-12v4h12Z"
          transform="translate(4208 4096)"
        />
      </g>
    </svg>
  );
}
 
export default PrintIcon;