import React, { useEffect, useRef } from 'react';
import TabShow from '../../_Common/TabShow';
import CloseIcon from '../../../../svgs/CloseIcon';
import { Translate } from '../../../../utils/lang/translate';
import IconButton from '../../_Common/IconButton';

interface DeleteOrderModalProps {
  onClose: () => void,
  onConfirm: () => void,
}

const DeleteOrderModal = ({ onClose, onConfirm }: DeleteOrderModalProps) => {

  const modalRef = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (e: MouseEvent) => {
    if(modalRef.current && !modalRef.current?.contains(e.target as Node)){
      onClose();
    }
  };

  useEffect(() => {
    window.addEventListener('mouseup', handleClickOutside);
    return () => window.removeEventListener('mouseup', handleClickOutside);
  }, []);

  return (
    <TabShow
      darkBg
      noReturn
      noPadding={true}
      style={{
        borderRadius: "8px",
        backgroundColor: "var(--defcl)",
        width: "fit-content"
      }}
    >
      <div 
        ref={modalRef} 
        className="InFlx Stclmnf" 
        style={{ 
          paddingTop: "10px", 
          paddingBottom: "10px", 
          gap: "10px", 
          minWidth: "fit-content" 
        }}
      >
        <div className="InFlx spcBtwn AlgnItm" style={{ padding: "5px 15px" }}>
          <div className="add-team-member-modal-header-text">
            {Translate("profile", "confirmaction")}
          </div>
          <div
            onClick={onClose} 
            className="InFlx AlgnItm sb4R CrsPoi Gray" 
            style={{ padding: "8px" }}
          >
            <CloseIcon/>
          </div>
        </div>
        <div className="add-staff-member-modal-divider"/>
        <div className="InFlx Stclmnf" style={{ gap: "20px", padding: "5px 15px" }}>
          <p className="DlMg" style={{ fontSize: "16px" }}>
            {Translate("orders", "confrmdelete")}
          </p>
        </div>
        <div className="InFlx JstfCnt" style={{ gap: "20px", padding: "5px 15px" }}>
          <IconButton
            clickHandler={onClose}
            label={Translate("profile", "cancel")}
            className="BdgBlcl"
          />
          <IconButton
            clickHandler={onConfirm}
            label={Translate("orders", "cancelorder")}
            className="TxDng"
          />
        </div>
      </div>
    </TabShow>
  );
}
 
export default DeleteOrderModal;