import React, { FormEvent, useCallback, useState } from 'react';
import { CheckSvg, OutSvg } from '../IconSvg';

interface SearchFilterPorps {
  onSearchChange: (value?: string) => void,
  placeholder: string,
  value: string | null | undefined
}

const SearchFilter = ({
  onSearchChange,
  placeholder,
  value
}: SearchFilterPorps) => {

  const [search, setSearch] = useState<string>("");
  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);
  const submitHandler = (e: FormEvent) => {
    e.preventDefault();
    if(value) {
      setSearch("");
      onSearchChange();
    }else {
      onSearchChange(search);
    }
  };

  return (
    <form 
      onSubmit={submitHandler}
      className="InFlx AlgnItm spcBtwn StBrdRdS ClSidTh h-filter"
      style={{ padding: "0 8px" }}
    >
      <input
        type="text"
        autoComplete="off"
        onChange={changeHandler}
        placeholder={placeholder}
        className="StPdsml DlBrd FntfMin StSizLn"
        value={value ? value : search}
        style={{ width: "300px" }}
      />
      <button 
        type="submit"
        className="InFlx BdgGc CrsPoi DlBrd StBgbrd" 
        style={{ padding: "10px" }}
      >
        {value ? OutSvg : CheckSvg}
      </button>
    </form>
  );
}
 
export default SearchFilter;