import React from 'react';

const ExportIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      width="24" 
      height="25" 
      viewBox="0 0 24 25" 
      fill="none"
      {...props}
    >
      <path d="M19 19.5027H5V5.50275H12V3.50275H5C4.46957 3.50275 3.96086 3.71346 3.58579 4.08853C3.21071 4.46361 3 4.97231 3 5.50275V19.5027C3 20.0332 3.21071 20.5419 3.58579 20.917C3.96086 21.292 4.46957 21.5027 5 21.5027H19C20.1 21.5027 21 20.6027 21 19.5027V12.5027H19V19.5027ZM14 3.50275V5.50275H17.59L7.76 15.3327L9.17 16.7427L19 6.91275V10.5027H21V3.50275H14Z" fill="var(--fntClr)"/>
    </svg>
  );
}
 
export default ExportIcon;