import { useEffect } from "react";

/**
 * Custom hook to detect clicks outside of a specified element.
 *
 * @param ref - The ref of the element to detect outside clicks for.
 * @param handler - The callback function to execute when an outside click is detected.
 */
const useClickOutside = <T extends HTMLElement | null>(
  ref: React.RefObject<T>,
  handler: () => void
) => {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      // If click is inside the element or ref is not set, do nothing
      if (!ref || !ref.current || ref.current.contains(event.target as Node)) {
        return;
      }
      handler(); // Call the handler when outside click is detected
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
};

export default useClickOutside;
