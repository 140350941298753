import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import ContainerPage from "../_Common/ContainerPage";
import { FormatDate, Translate } from "../../../utils/lang/translate";
import DatePicker from "../../_Common/DatePicker";

import { 
  NumberParam, 
  StringParam, 
  decodeDelimitedArray, 
  decodeDelimitedNumericArray, 
  encodeDelimitedArray, 
  encodeDelimitedNumericArray, 
  encodeQueryParams, 
  useQueryParams 
} from "use-query-params";

import { alertActions, commonActions, ordersActions, productActions } from "../../../actions";
import { CommuneInterface, Order2Submit, OrderDetails, ProductType, RequestStatus, TeamMember, wilayaType } from "../../../types";
import { stringify } from "query-string";
import CustomTable, { TableColumnProps } from "../_Common/CustomTable/CustomTable";
import { EmptyScreen } from "../_Common/EmptyScreen";
import { FeatureLoked } from "../_Common/FeatureLoked";
import PaginationBar from "../_Common/PaginationBar";
import CheckBoxForm from "../../_Common/CheckBoxForm";
import { 
  AddSvg, 
  CachbackSvg, 
  DeletSvg, 
  EditSvg, 
  FlechDwnSvg, 
  MoneySvg, 
  NewPrintSvg, 
  SubstitutionSvg, 
  ExchangeSvg 
} from "../_Common/IconSvg";
import { RouterChildContext, useHistory } from "react-router-dom";

import DropedList from "../_Common/DropedList";
import GroupeBtn from "../_Common/GroupeBtn";
import DeleteOrderModal from "./components/DeleteOrderModal";
import IconButton from "../_Common/IconButton";
import Filter from "../../../svgs/Filter";
import Refresh from "../../../svgs/Refresh";
import SearchFilter from "../_Common/SearchFilter/SearchFilter";
import Cookies from "js-cookie";
import useClickOutside from "../../../hooks/useClickOutside";
import CloseIcon from "../../../svgs/CloseIcon";
import ArrowIcon from "../../../svgs/ArrowIcon";
import { PREMIUM_FILTER_STATUS_LIST, STARTER_FILTER_STATUS_LIST } from "../../constants";
import CheckboxList from "../_Common/CheckboxList/CheckboxList";
import { teamsActions } from "../../../actions/teams.actions";
import PrintIcon from "../../../svgs/PrintIcon";
import ImportIcon from "../../../svgs/ImportIcon";
import ExcelIcon from "../../../svgs/ExcelIcon";
import ExportIcon from "../../../svgs/ExportIcon";
import DownloadIcon from "../../../svgs/DownloadIcon";
import PickupIcon from "../../../svgs/PickupIcon";
import { CreatePickupModal } from "./components/CreatePickupModal";

const lang = Cookies.get("lang");

const CommaArrayParam = {
  encode: (array: (number | null)[] | null | undefined) =>
    encodeDelimitedNumericArray(array, ','),

  decode: (arrayStr: string | (string | null)[] | null | undefined) =>
    decodeDelimitedNumericArray(arrayStr, ',')
};

const StringArrayParam = {
  encode: (array: (string | null)[] | null | undefined) =>
    encodeDelimitedArray(array, ','),

  decode: (arrayStr: string | (string | null)[] | null | undefined) =>
    decodeDelimitedArray(arrayStr, ',')
};

interface OrdersPageProps {
  dtStoreinfo: any,
  dataProfile: any,
  OrderGeting: RequestStatus,
  GetOrders: (filter: string, persistFilter?: boolean) => void,
  SendAlert: (code: string, text: string, action: string) => void,
  OrdersList: {
    ressuc: {
      list: {
        results: OrderDetails[],
        count: number,
        next: string | null,
        previous: string | null
      }
    },
    filter: string
  },
  GenerateOrdersBordureauStarter: (ids: string[], all_created?: boolean) => void,
  UpdateStaOrder: (order: Partial<Order2Submit>, order_id: string) => void,
  OrderUpdSta: "0" | "1" | "2",
  postingbordereau: RequestStatus,
  ClearBordereauState: () => void,
  Getwilayas: (country_id: number) => void,
  Getcommunes: (query: string, auth?: boolean) => void, 
  GetingWilaya: "0" | "1" | "2",
  dataWilaya: wilayaType[],
  GetigCommune: "0" | "1" | "2",
  DataCommune: CommuneInterface[],
  GettingAllProducts: RequestStatus,
  productsData: {
    list: ProductType[],
  },
  GetAllProductsV2: () => void,
  GetTeamMembers: () => void,
  GettingTeamMembersData: RequestStatus,
  teamMembersData: TeamMember[],
  ExportOrder: (filter: string, ext: "csv" | "xlsx" | "xls", is_temp_order: boolean) => void,
  Exporting: "0" | "1" | "2",
}

const Orders = ({
  OrderGeting,
  dataProfile,
  dtStoreinfo,
  GetOrders,
  SendAlert,
  OrdersList,
  GenerateOrdersBordureauStarter,
  UpdateStaOrder,
  OrderUpdSta,
  postingbordereau,
  ClearBordereauState,
  Getwilayas,
  Getcommunes,
  DataCommune,
  GetigCommune,
  GetingWilaya,
  dataWilaya,
  GetAllProductsV2,
  GettingAllProducts,
  productsData,
  GetTeamMembers,
  GettingTeamMembersData,
  teamMembersData,
  ExportOrder,
  Exporting
}: OrdersPageProps) => {

  const paramConfigMap = {
    display_id_or_external_id: StringParam,
    began: StringParam,
    end: StringParam,
    page: NumberParam,
    wilaya: CommaArrayParam,
    commune: CommaArrayParam,
    delivery_type: CommaArrayParam,
    source: CommaArrayParam,
    products: CommaArrayParam,
    status: CommaArrayParam,
    created_by: StringArrayParam
  };

  const history = useHistory<RouterChildContext['router']['history']>();

  const [query, setQuery] = useQueryParams(paramConfigMap);

  const filterRef = useRef<HTMLDivElement | null>(null);
  const printMenuRef = useRef<HTMLDivElement | null>(null);
  const importMenuRef = useRef<HTMLDivElement | null>(null);
  const exportMenuRef = useRef<HTMLDivElement | null>(null);

  const [selectedIDs, setSelectedIDs] = useState<string[]>([]);
  const [deleteId, setDeleteId] = useState<string | null>(null);

  const [refresh, setRefresh] = useState<boolean>(false);

  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [showPrintMenu, setShowPrintMenu] = useState<boolean>(false);
  const [showImportMenu, setShowImportMenu] = useState<boolean>(false);
  const [showExportMenu, setShowExportMenu] = useState<boolean>(false);

  const [showCreatePickupModal, setShowCreatePickupModal] = useState<boolean>(false);

  const onIDsChange = (id: string, all?: boolean) => {
    if(all){
      setSelectedIDs(
        prev => prev.length === OrdersList.ressuc.list.results.length 
        ? [] 
        : OrdersList.ressuc.list.results.map(order => order.display_id as string) 
      )
    }else{
      setSelectedIDs(
        prev => prev.indexOf(id) !== -1
        ? [...prev.filter(selected_id => selected_id !== id)]
        : [...prev, id]
      )
    }
  };

  const RefreshPage = () => setRefresh(prev => !prev);
  const onChangePage = (pageNumber: number) => {
    setQuery(prev => ({ ...prev, page: pageNumber }));
    setSelectedIDs([]);
  }

  const onChangeStatus = (status: number) => {

    setQuery(prev => {
      if(prev.status){
        if(prev.status.includes(status)) {
          if(prev.status.length === 1) return { ...prev, status: undefined, page: undefined };
          else return { ...prev, status: prev.status.filter(item => item !== status), page: undefined };
        }
        else return { ...prev, status: [...prev.status, status], page: undefined };
      }else return { ...prev, status: [status], page: undefined };
    });  
  };

  const onChangeProducts = (product_id: number) => {
    setQuery(prev => {
      if(prev.products){
        if(prev.products.includes(product_id)) {
          if(prev.products.length === 1) return { ...prev, products: undefined, page: undefined };
          else return { ...prev, products: prev.products.filter(item => item !== product_id), page: undefined };
        }
        else return { ...prev, products: [...prev.products, product_id], page: undefined };
      }else return { ...prev, products: [product_id], page: undefined };
    });
  };

  const onChangeWilaya = (wilaya_id: number) => {
    setQuery(prev => {
      if(prev.wilaya){
        if(prev.wilaya.includes(wilaya_id)) {
          if(prev.wilaya.length === 1) return { ...prev, wilaya: undefined, page: undefined };
          else return { ...prev, wilaya: prev.wilaya.filter(wilaya => wilaya !== wilaya_id), page: undefined };
        }
        else return { ...prev, wilaya: [...prev.wilaya, wilaya_id], page: undefined };
      }else return { ...prev, wilaya: [wilaya_id], page: undefined };
    });
  };

  const onChangeCommune = (commune_id: number) => {
    setQuery(prev => {
      if(prev.commune){
        if(prev.commune.includes(commune_id)) {
          if(prev.commune.length === 1) return { ...prev, commune: undefined, page: undefined };
          else return { ...prev, commune: prev.commune.filter(commune => commune !== commune_id), page: undefined };
        }
        else return { ...prev, commune: [...prev.commune, commune_id], page: undefined };
      }else return { ...prev, commune: [commune_id], page: undefined };
    });
  };

  const onChangeDeliveryType = (value: number) => {
    setQuery(
      prev => {
        return prev.delivery_type?.includes(value) 
        ? { ...prev, page: undefined, delivery_type: undefined }
        : { ...prev, page: undefined, delivery_type: [value] }
      } 
    );
  };

  const onChangeCreatedBy = (value: string) => {
    setQuery(prev => {
      if(prev.created_by){
        if(prev.created_by.includes(value)) {
          if(prev.created_by.length === 1) return { ...prev, created_by: undefined, page: undefined };
          else return { ...prev, created_by: prev.created_by.filter(item => item !== value), page: undefined };
        }
        else return { ...prev, created_by: [...prev.created_by, value], page: undefined };
      }else return { ...prev, created_by: [value], page: undefined };
    });
  };

  const onExportOrders = (ext: "csv" | "xlsx" | "xls") => {
    if(Exporting !== "0") {
      const encodedOrdersQuery = encodeQueryParams(paramConfigMap, {...query});
      ExportOrder(`&${stringify(encodedOrdersQuery)}`, ext, false);
      SendAlert("31", Translate("alert", "exportreqstsent"), "a04");
    }
  };

  const clearFilters = () => {
    let _query = { ...query };
    Object.keys(_query).forEach(key => {
      _query[key as keyof typeof query] = undefined;
    })
    setQuery(_query);
  };

  const status_id_list: string[] = 
    dtStoreinfo.stock_managed 
    ? PREMIUM_FILTER_STATUS_LIST 
    : STARTER_FILTER_STATUS_LIST
  ;

  const showResetFiltersButton: boolean = Object.keys(query).filter((key) => {
    if(key === "page" || key === "display_id_or_external_id" || key === "began" || key === "end") return false;
    if(key === "status" && (!query.status || query.status.length === 0) ) return false;
    if(key === "wilaya" && (!query.wilaya || query.wilaya.length === 0) ) return false;
    if(key === "commune" && (!query.commune || query.commune.length === 0) ) return false;
    if(key === "delivery_type" && (!query.delivery_type || query.delivery_type.length === 0) ) return false;
    if(key === "created_by" && (!query.created_by || query.created_by.length === 0) ) return false;
    if(key === "source" && (!query.source || query.source.length === 0) ) return false;
    if(key === "products" && (!query.products || query.products.length === 0) ) return false;
    else return true; 
  }).length > 0;

  const showPickupRequestButton = !dtStoreinfo.stock_managed && 
    selectedIDs.length > 0 && 
    selectedIDs.filter(
      selected_id => OrdersList?.ressuc?.list?.results.find(
        order => order.display_id === selected_id
      )?.status === 4
    ).length > 0
  ;
  
  const delivery_type_options = [
    {
      id: 1,
      name: Translate("orders", "home"),
    },
    {
      id: 2,
      name: Translate("orders", "stpdsk"),
    },
    {
      id: 3,
      name: Translate("orders", "pickuppoint"),
    }
  ];

  type OrderDetailsWithAction = OrderDetails & { action: string };
  const table_cols: TableColumnProps<OrderDetailsWithAction, keyof OrderDetailsWithAction>[] = [
    {
      key: "id",
      title: "",
      renderItem: (item) => (
        <CheckBoxForm
          name={item.display_id}
          rmMarg={true}
          workfun={() => onIDsChange(item.display_id as string)}
          check={selectedIDs.indexOf(item.display_id as string) !== -1}
          id={"order_print_" + item.display_id}
        />
      ),
      render: () => (
        <CheckBoxForm
          name={"select_all_orders_checkbox"}
          rmMarg={true}
          workfun={() => onIDsChange("", true)}
          check={selectedIDs.length === OrdersList?.ressuc?.list?.results.length}
          id={"select_all_orders_checkbox"}
        />
      ),
      renderLoading: () => (
        <div 
          className="StBrdRd HdOvrfl animate-pulse"
          style={{
            height: "26px",
            width: "26px",
            backgroundColor: "var(--bdgclInp)"
          }}
        />
      )
    },
    {
      key: "display_id",
      title: Translate("product", "id"),
      renderItem: (item) => (
        <div className="Inlflx Stclmnf AlgnItm JstfCnt">
          {item.is_exchange && (
            <div title={Translate("orders", "isanexchange")}>
              {SubstitutionSvg}
            </div>
          )}
          {item.exchanged_for_order && (
            <div
              className="disabledSvg"
              title={Translate("orders", "isexchanged")}
            >
              {SubstitutionSvg}
            </div>
          )}
          {item.is_refund && (
            <div className="" title={Translate("orders", "isrefun")}>
              {MoneySvg}
            </div>
          )}
          {item.refunded_for_order && (
            <div
              className="StNoFil disabledSvg "
              title={Translate("orders", "isrefunded")}
            >
              {MoneySvg}
            </div>
          )}
          <div
            className="InFlx Stclmnf CrsPoi"
            onClick={() => history.push("/order/detail/" + item.display_id + "?source=order")}
          >
            {item.display_id}
          </div>
        </div>
      )
    },
    {
      key: "status",
      title: Translate("orders", "status"),
      renderItem: (item) => (
        <div 
          style={{ padding: "6px 15px" }}
          className={`CrsPoi StWht StBrdRd HdOvrfl TxtCn status_${item.status}`}
          onClick={() => history.push(`/order/detail/${item.display_id}?source=order`)}
        >
          {Translate("statusorder", item.status)}
        </div>
      ),
      renderLoading: () => (
        <div 
          className="StBrdRd HdOvrfl animate-pulse" 
          style={{ 
            width: "150px", 
            height: "26px", 
            backgroundColor: "var(--bdgclInp)" 
          }}
        />
      )
    },
    {
      key: "products",
      title: Translate("product", "productname"),
      renderItem: (item) => (
        item.products.length > 1 
        ?
        <div className="CrsPoi">
          <DropedList
            title={
              <div className="InFlx AlgnItm">
                <span>{item.products.length}</span>
                <span className="StPaddingVal">
                  {Translate("titles", "product")}
                </span>
                <span className="DsPlCnt">{FlechDwnSvg}</span>
              </div>
            }
            dataDrop={item.products.map((product, index) => (
              <div key={index}>{`(${product.quantity}) ${product.logistical_description}`}</div>
            ))} 
          />  
        </div>
        :
          item.products.length > 0
          ?
            <div 
              className="CrsPoi"
              onClick={() => history.push("/order/detail/" + item.display_id + "?source=order")}
            >
              {`(${item.products[0].quantity}) ${item.products[0].logistical_description}`}
            </div>
          : 
            null
      )
    },
    {
      key: "total_price",
      title: Translate("orders", "totalprice")
    },
    {
      key: "customer_name",
      title: Translate("orders", "customername")
    },
    {
      key: "commune",
      title: `${Translate("orders", "district")}/${Translate("product", "wilaya")}`,
      renderItem: (item) => (
        <div
          className="CrsPoi"
          onClick={() => history.push(`/order/detail/${item.display_id}?source=order`)}
        >
          {`${item.commune_name} / ${item.wilaya}`}
        </div>
      )
    },
    {
      key: "updated_at",
      title: Translate("orders", "creationdate"),
      renderItem: (item) => (
        <div 
          className="CrsPoi"
          style={{ textAlign: "center" }}
          onClick={() => history.push(`/order/detail/${item.display_id}?source=order`)}
        >
          { FormatDate(item.created_at) }
        </div>
      )
    },
    {
      key: "external_order_id",
      title: Translate("orders", "External ID")
    },
    {
      key: "action",
      title: Translate("product", "action"),
      renderItem: (item) => {

        let actions: any[] = [];

        const EDIT_ACTION = {
          type: "BDgInpc",
          action: () =>
            history.push("/order/" + item.id + "?source=order"),
          svgbtn: EditSvg,
          tooltip: Translate("orders", "editorder"),
        };
        const DELETE_ACTION = {
          type: "BDgInpc",
          action: () => setDeleteId(item.id),
          svgbtn: DeletSvg,
          tooltip: Translate("profile", "cancel"),
        };

        const PRINT_ACTION_NEW = {
          type: "BDgInpc",
          action: () =>
            GenerateOrdersBordureauStarter([item.display_id as string]),
          svgbtn: NewPrintSvg,
          tooltip: Translate("tooltips", "newformatpdf"),
        };

        const RELAUNCH_ACTION = {
          type: "BDgInpc",
          action: () =>
            history.push(
              "/order/" + item.id + "?source=cancelled"
            ),
          svgbtn: AddSvg,
        };

        const REFUND_ACTION = {
          type: "BDgInpc",
          action: () =>
            history.push(
              "/refund/" + item.id
            ),
          svgbtn: CachbackSvg,
          tooltip: Translate("orders", "makerefund"),
        };

        const EXCHANGE_ACTION = {
          type: "BDgInpc",
          action: () =>
            history.push("/exchange/" + item.id),
          svgbtn: ExchangeSvg,
          tooltip: Translate("orders", "makeexchange"),
        };

        if(dataProfile.is_store || dataProfile.change_order){
          if(dtStoreinfo.country.id === 1){
            if(!item.is_refund){
              if (item.status < 31 && [9, 15, 22, 42].indexOf(item.status) === -1) actions.push(EDIT_ACTION);
              if ([4, 5, 8, 11, 12].indexOf(item.status) > -1 || (item.delivery_type === 3 && [9, 15, 41, 42, 50, 51].indexOf(item.status) === -1)) actions.push(DELETE_ACTION);
            }else{
              if(item.status === 15) {
                actions.push(EDIT_ACTION);
                actions.push(DELETE_ACTION);
              }
            }
          }else{
            actions.push(EDIT_ACTION);
            actions.push(DELETE_ACTION);
          }
        }

        if(item.status === 41){
          let delivered_order_actions = [];
          if(!item.is_exchange && !item.exchanged_for_order){
            delivered_order_actions.push(EXCHANGE_ACTION);
          }
          if(!item.is_refund && !item.refunded_for_order){
            delivered_order_actions.push(REFUND_ACTION);
          }
          actions = delivered_order_actions;
        }else{
          if(!dtStoreinfo.stock_managed) actions.push(PRINT_ACTION_NEW);
        };

        if(
          item.status === 50 
          && item.intra_status !== 75 
          && dtStoreinfo.stock_managed
        ) {
          actions = [RELAUNCH_ACTION];
        };

        return (
          <div className="InFlx JstfCnt" style={{ padding: "0 20px" }}>
            <GroupeBtn 
              data_btn={actions}
            />
          </div>
        )
      },
      renderLoading: () => (
        <div 
          className="StBrdRd MrAot HdOvrfl animate-pulse" 
          style={{ 
            width: "60px", 
            height: "32px", 
            backgroundColor: "var(--bdgclInp)" 
          }}
        />
      )
    }
  ];

  useClickOutside(filterRef, () => setShowFilter(false));
  useClickOutside(printMenuRef, () => setShowPrintMenu(false));
  useClickOutside(importMenuRef, () => setShowImportMenu(false));
  useClickOutside(exportMenuRef, () => setShowExportMenu(false));

  useEffect(() => {

    const encodedOrdersQuery = encodeQueryParams(paramConfigMap, {...query});

    GetOrders(`${stringify(encodedOrdersQuery)}`);

  }, [...Object.values(query), refresh]);

  useEffect(() => {
    Getwilayas(dtStoreinfo.country.id);
  }, []);

  useEffect(() => {
    Getcommunes("");
  }, []);

  useEffect(() => {

    if(
      GetingWilaya === '2' || 
      GetigCommune === '2'
    ) SendAlert("50", "Failed to fetch wilaya/commune data!", "");

  }, [GetingWilaya, GetigCommune]);

  useEffect(() => {
    GetAllProductsV2();
  }, []);

  useEffect(() => {

    if(GettingAllProducts === '3') 
    SendAlert("50", "Failed to fetch products data!", "");
  
  }, [GettingAllProducts]);

  useEffect(() => {
    if(dtStoreinfo.is_store) GetTeamMembers();
  }, []);

  useEffect(() => {

    if(GettingTeamMembersData === "3") 
    SendAlert("50", "Failed to fetch team data!", "");

  }, [GettingTeamMembersData]);

  useEffect(() => {
    if(OrderGeting === "3") SendAlert("50", "Failed to fetch data!", "");
  }, [OrderGeting]);

  useEffect(() => {

    if(OrderUpdSta === "2") SendAlert("50", Translate("alert", "failupdateorder"), "");
    else if(OrderUpdSta === "1") SendAlert("50", Translate("alert", "successcancelorder"), "");
    else if(OrderUpdSta === "0") SendAlert("31", Translate("alert", "canceling"), "");
  }, [OrderUpdSta]);

  useEffect(() => {
    if(postingbordereau === "1") {
      SendAlert("31", Translate("alert", "exportreqstsent"), "");
    } else if(postingbordereau === "2") {
      SendAlert("41", Translate("alert", "succesexprt"), "");
    } else if(postingbordereau === "3") {
      SendAlert("50", Translate("alert", "tryagain"), "");
    };

    return () => ClearBordereauState();
  }, [postingbordereau]);

  return (
    <ContainerPage 
      page_title={Translate("titles", "order")}
      data_top={
        <DatePicker
          forceUp={false}
          from={query.began}
          to={query.end}
          changeFrom={(from: string) => setQuery(prev => ({ ...prev, began: from }))}
          changeTo={(to: string) => setQuery(prev => ({ ...prev, end: to }))}
        />
      }
    >
      {
        dataProfile.is_store || dataProfile.view_order
        ?
          <div>
            {
              OrderGeting === "3"
              ?
                <div>Failed to fetch orders data!</div>
              :
                <div className="StmpB3 InFlx AlgnItm flex-wrap" style={{ gap: "12px" }}>
                  <div 
                    ref={filterRef}
                    className="RlPs"
                  >
                    <IconButton 
                      icon={<Filter/>}
                      clickHandler={() => setShowFilter(prev => !prev)}
                      tooltip={Translate("orders", "filter")}
                      className="BdgBlcl"
                    />
                    <div 
                      className="StAbs StBgbrds ClSidTh InFlx Stclmnf stTranEs StBxSh1 HdOvrfl" 
                      style={{ 
                        gap: "15px", 
                        padding: "12px",
                        width: "300px",
                        ...lang === "ar" ? { right: "0px" } : { left: "0px" },
                        ...showFilter ? { opacity: 1, top: "50px", zIndex: 2 } : { opacity: 0, top: "60px", zIndex: -10 }
                      }}
                    >
                      <div 
                        className="InFlx AlgnItm spcBtwn CrsPoi"
                      >
                        <h4 className="DlMg pointer-none">{Translate("orders", "filter")}</h4>
                        <CloseIcon
                          className="CrsPoi" 
                          onClick={() => setShowFilter(false)}
                        />
                      </div>
                      {
                        showResetFiltersButton
                        &&
                        <div 
                          onClick={clearFilters}
                          className="CrsPoi StBle"
                        >
                          {Translate("orders", "resetfilter")}
                        </div>
                      }
                      <CustomDropdown 
                        title={Translate("orders", "orderstatus")}
                      >
                        <div className="InFlx Stclmnf">
                          {
                            status_id_list.map(id => (
                              <CheckBoxForm
                                key={id}
                                id={"status_" + id}
                                name={"status_" + id}
                                text={Translate("statusorder", id)}
                                workfun={() => onChangeStatus(parseInt(id))}
                                check={query.status && query.status?.includes(parseInt(id))}
                              />
                            ))
                          }
                        </div>
                      </CustomDropdown>
                      <CustomDropdown 
                        title={Translate("product", "productname")}
                      >
                        <CheckboxList
                          field2Show={"logistical_description"}
                          fieldValue={"display_id"}
                          checkedValues={query.products as number[] ?? []}
                          placeholder={Translate("product","productname")}
                          options={GettingAllProducts === "2" ? productsData.list : []}
                          onItemCheck={(value: number | string) => onChangeProducts(value as number)}
                          withSearch
                        />
                      </CustomDropdown>
                      <CustomDropdown title={Translate("product", "wilaya")}>
                        <CheckboxList
                          options={GetingWilaya === "1" ? dataWilaya : []}
                          field2Show={1}
                          fieldValue={0}
                          placeholder={Translate("product", "wilaya")}
                          onItemCheck={(value: number | string) => onChangeWilaya(value as number)}
                          checkedValues={(query.wilaya as number[]) ?? []}
                          withSearch
                        />
                      </CustomDropdown>
                      <CustomDropdown title={Translate("orders", "district")}>
                        <CheckboxList
                          options={GetigCommune === "1" ? DataCommune : []}
                          field2Show={"name"}
                          fieldValue={"id"}
                          placeholder={Translate("orders", "district")}
                          onItemCheck={(value: number | string) => onChangeCommune(value as number)}
                          checkedValues={(query.commune as number[]) ?? []}
                          withSearch
                        />
                      </CustomDropdown>
                      <CustomDropdown 
                        title={Translate("orders", "dekiverytype")}
                      >
                        <div className="InFlx Stclmnf">
                          {
                            delivery_type_options.map(option => (
                              <CheckBoxForm
                                key={option.id}
                                id={"delivery_type_" + option.id}
                                name={"delivery_type_" + option.id}
                                text={option.name}
                                workfun={() => onChangeDeliveryType(option.id)}
                                check={query.delivery_type && query.delivery_type.includes(option.id)}
                              />
                            ))
                          }
                        </div>
                      </CustomDropdown>
                      {
                        dtStoreinfo.is_store
                        &&
                        <CustomDropdown 
                          title={Translate("orders", "createdby")}
                        >
                          <CheckboxList
                            field2Show={"full_name"}
                            fieldValue={"user_id"}
                            onItemCheck={onChangeCreatedBy}
                            checkedValues={(query.created_by as string[]) ?? []}
                            options={GettingTeamMembersData === "2" ? teamMembersData : []}
                            placeholder={Translate("orders", "createdby")}
                            withSearch
                          />
                        </CustomDropdown>
                      }
                    </div>
                  </div>
                  <SearchFilter
                    placeholder={Translate("orders", "filterbyid")}
                    value={query.display_id_or_external_id}
                    onSearchChange={(new_value?: string) => {
                      if(!query.display_id_or_external_id && new_value !== ""){
                        setQuery({ ...query, display_id_or_external_id: new_value, page: undefined });
                      }else {
                        setQuery({ ...query, display_id_or_external_id: undefined, page: undefined });
                      }
                    }}
                  />
                  <IconButton
                    clickHandler={RefreshPage}
                    tooltip={Translate("tooltips", "refresh")}
                    icon={<Refresh/>}
                    className="h-filter"
                  />
                  {
                    showPickupRequestButton 
                    &&
                    <IconButton
                      clickHandler={() => setShowCreatePickupModal(true)}
                      icon={<PickupIcon />}
                      tooltip={Translate("tooltips", "createpickup")}
                      className="h-filter"
                    /> 
                  }
                  <div 
                    ref={printMenuRef}
                    className="RlPs"
                  >
                    <IconButton
                      clickHandler={() => setShowPrintMenu(prev => !prev)}
                      icon={<PrintIcon height={24} width={24}/>}
                      label={Translate("titles", "print")}
                      className="h-filter"
                    />
                    <div
                      className="StAbs StBgbrds ClSidTh InFlx Stclmnf stTranEs StBxSh1 HdOvrfl" 
                      style={{ 
                        gap: "5px", 
                        padding: "12px",
                        width: "300px",
                        ...lang === "ar" ? { right: "0px" } : { left: "0px" },
                        ...showPrintMenu ? { opacity: 1, top: "50px", zIndex: 2 } : { opacity: 0, top: "60px", zIndex: -10 }
                      }}
                    >
                      <div 
                        className="InFlx AlgnItm CrsPoi" 
                        style={{ gap: "5px" }}
                        onClick={() => GenerateOrdersBordureauStarter([], true)}
                      >
                        <PrintIcon height={30} width={30} fill="#EAB600"/>
                        <div className="StSmlS" style={{ textAlign: "start" }}>
                          {Translate("tooltips", "printallcreated")}
                        </div>
                      </div>
                      <hr className="FlWd"/>
                      <div 
                        className={`InFlx AlgnItm${selectedIDs.length > 0 ? " CrsPoi" : " notAllowed StOpcVal"}`} 
                        style={{ gap: "5px" }}
                        onClick={() => GenerateOrdersBordureauStarter(selectedIDs, false)}
                      >
                        <PrintIcon height={30} width={30} fill="green"/>
                        <div className="StSmlS" style={{ textAlign: "start" }}>
                          {Translate("tooltips", "newformatpdf")}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div 
                    ref={importMenuRef}
                    className="RlPs"
                  >
                    <IconButton 
                      clickHandler={() => setShowImportMenu(prev => !prev)} 
                      icon={<ImportIcon/>} 
                      label={Translate("titles", "import")} 
                      className="h-filter"
                    />
                    <div
                      className="StAbs StBgbrds ClSidTh InFlx Stclmnf stTranEs StBxSh1 HdOvrfl" 
                      style={{ 
                        gap: "5px", 
                        padding: "12px",
                        width: "300px",
                        ...lang === "ar" ? { right: "0px" } : { left: "0px" },
                        ...showImportMenu ? { opacity: 1, top: "50px", zIndex: 2 } : { opacity: 0, top: "60px", zIndex: -10 }
                      }}
                    >
                      <div 
                        className="InFlx AlgnItm CrsPoi" 
                        style={{ gap: "5px" }}
                        onClick={() => history.push("/import-orders")}
                      >
                        <ExcelIcon />
                        <div className="StSmlS" style={{ textAlign: "start" }}>
                          {Translate("orders", "importorder")}
                        </div>
                      </div>
                      <hr className="FlWd"/>
                      <div 
                        className="InFlx AlgnItm CrsPoi" 
                        style={{ gap: "5px" }}
                        onClick={() => history.push("/import-orders-with-products")}
                      >
                        <ExcelIcon />
                        <div className="StSmlS" style={{ textAlign: "start" }}>
                          {Translate("orders", "importorderprd")}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    ref={exportMenuRef}
                    className="RlPs" 
                  > 
                    <IconButton 
                      label={Translate("titles", "export")} 
                      icon={<ExportIcon/>} 
                      clickHandler={() => setShowExportMenu(prev => !prev)}
                      className="h-filter"
                    />
                    <div
                      className="StAbs StBgbrds ClSidTh InFlx Stclmnf stTranEs StBxSh1 HdOvrfl" 
                      style={{ 
                        gap: "5px", 
                        padding: "12px",
                        width: "300px",
                        ...lang === "ar" ? { right: "0px" } : { left: "0px" },
                        ...showExportMenu ? { opacity: 1, top: "50px", zIndex: 2 } : { opacity: 0, top: "60px", zIndex: -10 }
                      }}
                    >
                      <div 
                        className="InFlx AlgnItm CrsPoi" 
                        style={{ gap: "5px" }}
                        onClick={() => onExportOrders("csv")}
                      >
                        <DownloadIcon />
                        <div className="StSmlS" style={{ textAlign: "start" }}>
                          {Translate("orders", "exportordcsv")}
                        </div>
                      </div>
                      <hr className="FlWd"/>
                      <div 
                        className="InFlx AlgnItm CrsPoi" 
                        style={{ gap: "5px" }}
                        onClick={() => onExportOrders("xlsx")}
                      >
                        <ExcelIcon />
                        <div className="StSmlS" style={{ textAlign: "start" }}>
                          {Translate("orders", "exportordxsl2007")}
                        </div>
                      </div>
                      <hr className="FlWd"/>
                      <div 
                        className="InFlx AlgnItm CrsPoi" 
                        style={{ gap: "5px" }}
                        onClick={() => onExportOrders("xlsx")}
                      >
                        <ExcelIcon />
                        <div className="StSmlS" style={{ textAlign: "start" }}>
                          {Translate("orders", "exportordxsl97")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            }
            {
              OrderGeting === "2" && OrdersList.ressuc?.list?.count === 0
              ?
              <EmptyScreen
                titleGlb={Translate("orders", "noorder")}
                TextShow={Translate("refunds", "norefundsyet")}
                PictShow={"/assets/img/exchange.png"}
                actionBtns={
                  showResetFiltersButton
                  ?
                    [
                      {
                        text: Translate("orders", "resetfilter"),
                        to: `/orders`,
                      }
                    ]
                  :
                    null
                }
              />
              :
                <>
                  <div 
                    className="InFlx AlgnItm flex-wrap" 
                    style={{ gap: "10px", padding: "10px 0" }}
                  >
                    {
                      query.began && query.end
                      &&
                      <>
                        <div className="StSmlS">{Translate("datepicker", "daterange")}</div>
                        <div
                          onClick={() => setQuery(prev => ({ ...prev, began: undefined, end: undefined }))} 
                          className="InFlx AlgnItm CrsPoi" 
                          style={{ 
                            border: "2px solid #3498DB",
                            padding: "4px 6px",
                            backgroundColor: "#3498DB35",
                            borderRadius: "5px",
                            gap: "8px" 
                          }}
                        >
                          <div className="StSmlS">
                            {`${FormatDate(query.began, true)} => ${FormatDate(query.end, true)}`}
                          </div>
                          <CloseIcon height={12} width={12}/>
                        </div>
                      </>
                    }
                    {
                      query.status && query.status.length > 0
                      &&
                      <>
                        <div className="StSmlS">{Translate("orders", "orderstatus")}</div>
                        {
                          (query.status as number[]).map((item, index) => (
                            <div
                              key={index}
                              onClick={() => onChangeStatus(item)} 
                              className="InFlx AlgnItm CrsPoi" 
                              style={{ 
                                border: "2px solid #3498DB",
                                padding: "4px 6px",
                                backgroundColor: "#3498DB35",
                                borderRadius: "5px",
                                gap: "8px" 
                              }}
                            >
                              <div className="StSmlS">
                                {Translate("statusorder", item.toString())}
                              </div>
                              <CloseIcon height={12} width={12}/>
                            </div>
                          ))
                        }
                      </>
                    }
                    {
                      query.products && query.products.length > 0 && GettingAllProducts === "2"
                      &&
                      <>
                        <div className="StSmlS">{Translate("product", "productname")}</div>
                        {
                          (query.products as number[]).map((item, index) => (
                            <div
                              key={index}
                              onClick={() => onChangeProducts(item)} 
                              className="InFlx AlgnItm CrsPoi" 
                              style={{ 
                                border: "2px solid #3498DB",
                                padding: "4px 6px",
                                backgroundColor: "#3498DB35",
                                borderRadius: "5px",
                                gap: "8px" 
                              }}
                            >
                              <div className="StSmlS">
                                {productsData.list.find(product => product.display_id === item)?.logistical_description}
                              </div>
                              <CloseIcon height={12} width={12}/>
                            </div>
                          ))
                        }
                      </>
                    }
                    {
                      query.wilaya && query.wilaya.length > 0 && GetingWilaya === "1"
                      &&
                      <>
                        <div className="StSmlS">{Translate("product", "wilaya")}</div>
                        {
                          (query.wilaya as number[]).map((item, index) => (
                            <div
                              key={index}
                              onClick={() => onChangeWilaya(item)} 
                              className="InFlx AlgnItm CrsPoi" 
                              style={{ 
                                border: "2px solid #3498DB",
                                padding: "4px 6px",
                                backgroundColor: "#3498DB35",
                                borderRadius: "5px",
                                gap: "8px" 
                              }}
                            >
                              <div className="StSmlS">
                                {dataWilaya.find(wilaya => wilaya[0] === item)?.[1]}
                              </div>
                              <CloseIcon height={12} width={12}/>
                            </div>
                          ))
                        }
                      </>
                    }
                    {
                      query.commune && query.commune.length > 0 && GetigCommune === "1"
                      &&
                      <>
                        <div className="StSmlS">{Translate("orders", "district")}</div>
                        {
                          (query.commune as number[]).map((item, index) => (
                            <div
                              key={index}
                              onClick={() => onChangeCommune(item)} 
                              className="InFlx AlgnItm CrsPoi" 
                              style={{ 
                                border: "2px solid #3498DB",
                                padding: "4px 6px",
                                backgroundColor: "#3498DB35",
                                borderRadius: "5px",
                                gap: "8px" 
                              }}
                            >
                              <div className="StSmlS">
                                {DataCommune.find(commune => commune.id === item)?.name}
                              </div>
                              <CloseIcon height={12} width={12}/>
                            </div>
                          ))
                        }
                      </>
                    }
                    {
                      query.delivery_type && query.delivery_type.length > 0
                      &&
                      <>
                        <div className="StSmlS">{Translate("orders", "dekiverytype")}</div>
                        {
                          (query.delivery_type as number[]).map((item, index) => (
                            <div
                              key={index}
                              onClick={() => onChangeDeliveryType(item)} 
                              className="InFlx AlgnItm CrsPoi" 
                              style={{ 
                                border: "2px solid #3498DB",
                                padding: "4px 6px",
                                backgroundColor: "#3498DB35",
                                borderRadius: "5px",
                                gap: "8px" 
                              }}
                            >
                              <div className="StSmlS">
                                {delivery_type_options.find(option => option.id === item)?.name}
                              </div>
                              <CloseIcon height={12} width={12}/>
                            </div>
                          ))
                        }
                      </>
                    }
                    {
                      query.created_by && query.created_by.length > 0 && GettingTeamMembersData === "2"
                      &&
                      <>
                        <div className="StSmlS">{Translate("orders", "district")}</div>
                        {
                          (query.created_by as string[]).map((item, index) => (
                            <div
                              key={index}
                              onClick={() => onChangeCreatedBy(item)} 
                              className="InFlx AlgnItm CrsPoi" 
                              style={{ 
                                border: "2px solid #3498DB",
                                padding: "4px 6px",
                                backgroundColor: "#3498DB35",
                                borderRadius: "5px",
                                gap: "8px" 
                              }}
                            >
                              <div className="StSmlS">
                                {teamMembersData.find(member => member.user_id === item)?.full_name}
                              </div>
                              <CloseIcon height={12} width={12}/>
                            </div>
                          ))
                        }
                      </>
                    }

                  </div>  
                  <CustomTable
                    columns={table_cols} 
                    data={
                      OrderGeting === "2" ? 
                        OrdersList?.ressuc?.list?.results
                        .map(order => ({ ...order, action: "" }))
                      : 
                        []
                    } 
                    loading={OrderGeting === "1"}
                    border={false}
                    sticky={true}
                  />
                  <div role="pagination" className="lytWdp SmMarS1">
                    <div className="InFlx flxDrc FlWd RlPs AlgnItm">
                      <span className="d-large">
                        {
                          Translate("orders", "showing") +
                          " " +
                          ((query.page ?? 1) * 20 - 19) +
                          " - " +
                          (
                            (query.page ?? 1) * 20 < (OrdersList?.ressuc?.list?.count ?? 0)
                            ? (query.page ?? 1) * 20
                            : OrdersList?.ressuc?.list?.count ?? 0
                          ) +
                          " " +
                          Translate("orders", "of") +
                          " " +
                          OrdersList?.ressuc?.list?.count ?? 0
                        }
                      </span>
                      <PaginationBar
                        className="StAutMr"
                        NbPage={Math.trunc((OrdersList?.ressuc?.list?.count ?? 0) / 20) + 1}
                        currentPage={query.page ?? 1}
                        ChangePage={onChangePage}
                        blockNext={OrdersList?.ressuc?.list?.next}
                        blockPrev={OrdersList?.ressuc?.list?.previous}
                      />
                    </div>
                  </div>
                </>
            }
            {
              deleteId !== null
              &&
              <DeleteOrderModal 
                onClose={() => setDeleteId(null)}
                onConfirm={() => {
                  UpdateStaOrder({ status: 50 }, deleteId);
                  setDeleteId(null)
                }}
              />
            }
            {
              showCreatePickupModal
              &&
              <CreatePickupModal 
                onClose={() => setShowCreatePickupModal(false)}
                selected_orders={
                  OrdersList.ressuc.list.results
                  .filter(order => selectedIDs.indexOf(order.display_id as string) !== -1)
                  .map(order => order.id as string)
                }
              />
            }
          </div>
        :
          <FeatureLoked/>
      }
    </ContainerPage>
  );
}

function mapState(state: any) {

  const {
    OrdersList,
    OrderGeting,
    OrderUpdSta,
    postingbordereau,
  } = state.orders;

  const { Exporting } = state.listener;

  const { GettingAllProducts, productsData } = state.product;

  const {
    GetingWilaya,
    dataWilaya,
    GetigCommune,
    DataCommune,
  } = state.common;

  const { GettingTeamMembersData, teamMembersData } = state.teams;
  const { dtStoreinfo, dataProfile } = state.user;
  
  return {
    OrdersList,
    OrderGeting,
    dtStoreinfo,
    dataProfile,
    OrderUpdSta,
    postingbordereau,
    GetingWilaya,
    dataWilaya,
    GetigCommune,
    DataCommune,
    GettingAllProducts, 
    productsData,
    GettingTeamMembersData,
    teamMembersData,
    Exporting,
  };
};

const actionCreators = {
  GetOrders: ordersActions.GetOrders,
  GenerateOrdersBordureauStarter: ordersActions.GenerateOrdersBordureauStarter,
  SendAlert: alertActions.SendAlert,
  UpdateStaOrder: ordersActions.UpdateStaOrder,
  ClearBordereauState: commonActions.ClearBordereauState,
  Getwilayas: commonActions.Getwilayas,
  Getcommunes: commonActions.Getcommunes,
  GetAllProductsV2: productActions.GetAllProductsV2,
  GetTeamMembers: teamsActions.GetTeamMembers,
  ExportOrder: ordersActions.ExportOrder
};

const connectedOrdersPage = connect(mapState, actionCreators)(Orders);

export { connectedOrdersPage as Orders };

interface CustomDropdownProps {
  children: React.ReactNode,
  title: string,
};

const CustomDropdown = ({ children, title }: CustomDropdownProps) => {

  const [drop, setDrop] = useState(false); 
  const dropRef = useRef<HTMLDivElement | null>(null);
  
  const toggleDropdown = () => setDrop(prev => !prev)
  useClickOutside(dropRef, () => setDrop(false));

  return (
    <div 
      ref={dropRef}
      className="BdgClN StBrdRdS pdnVldas"
    >
      <div 
        className="InFlx AlgnItm spcBtwn CrsPoi"
        onClick={toggleDropdown}
      >
        <strong>{title}</strong>
        <ArrowIcon height={24}/>
      </div>
      <div 
        className={`InFlx HidScrolStil stTranEs${drop ? "" : " hidElem3"}`}
        style={ drop ? { maxHeight: "400px" } : {}}
      >
        {children}
      </div>
    </div>
  );
}