import React from 'react';

const DownloadIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 25"
      height={24}
      width={24}
      fill='var(--fntClr)'
      {...props}
    >
      <g transform="translate(-1450 -389)">
        <path
          d="M1059.792-137.946h-3.369V-143h-5.054v5.054H1048l5.9,5.9,5.9-5.9ZM1048-130.366v1.685h11.792v-1.685Z"
          transform="translate(408.738 537.896)"
        />
      </g>
    </svg>
  );
}
 
export default DownloadIcon;