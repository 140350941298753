import React from 'react';

const Refresh = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className=""
      viewBox="0 0 30 30"
      fill="var(--fntClr)"
      height={"24px"}
      {...props}
    >
      <g transform="translate(-500 -489)">
        <path
          d="M300.64-60.65A7.958,7.958,0,0,0,294.99-63,7.989,7.989,0,0,0,287-55a7.989,7.989,0,0,0,7.99,8,7.983,7.983,0,0,0,7.73-6h-2.08a5.991,5.991,0,0,1-5.65,4,6,6,0,0,1-6-6,6,6,0,0,1,6-6,5.915,5.915,0,0,1,4.22,1.78L295.99-56h7v-7l-2.35,2.35Z"
          transform="translate(220 559)"
        />
      </g>
    </svg>
  );
}
 
export default Refresh;