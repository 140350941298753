import { ordersConstants, userConstants } from "../constants";
import { RequestService, FunctionService } from "../services";
import Cookies from "js-cookie";
import { getUnfCountFilters } from "../utils/helpers";
const token = Cookies.get("auth");
const lang = Cookies.get("lang");
export const ordersActions = {
  AddOrder,
  ClearAddOrder,
  GetOrders,
  GetOrderHistory,
  UpdateOrder,
  UpdateStaOrder,
  GetUnfOrders,
  GetAllUnfOrders,
  GetUnfOrderDetails,
  GetUnfOrderTimeline,
  UpdateUnfOrder,
  GetConfirmationOrders,
  GetConfirmationSummary,
  ExportOrder,
  ImportOrder,
  ClearOrderStore,
  GetWebHooks,
  DeleteWebHooks,
  PostWebHooks,
  TestWebHooks,
  GetHooksEvents,
  ImportOdrProds,
  GenerateOrdersBordureauStarter,
  GetUnfOrdersCount,
  GetOrderDetail,
  GetCurrentorders,
  EditCallbackInUnfOrder,
  EditPostponedInUnfOrder,
  GetOrder,
  GetTempOrder,
  ClearUpdateUnfOrderData
};
function AddOrder(order) {
  return (dispatch) => {
    dispatch(FunctionService.request(ordersConstants.ADD_ORDER_REQUEST));
    RequestService.PostRequest("stores/orders/", order, {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(res.data, ordersConstants.ADD_ORDER_SUCCESS)
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(err, ordersConstants.ADD_ORDER_FAILURE)
        );
      });
  };
};

function ClearAddOrder() {
  return (dispatch) => {
    dispatch(FunctionService.request(ordersConstants.CLEAR_ADD_ORDER_STATE));
  }
};

function ImportOrder(orders, product) {
  return (dispatch) => {
    dispatch(FunctionService.request(ordersConstants.IMPORT_ORDER_REQUEST));
    RequestService.PostRequest("stores/import_order/" + product + "/", orders, {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(res, ordersConstants.IMPORT_ORDER_SUCCESS)
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(err, ordersConstants.IMPORT_ORDER_FAILURE)
        );
      });
  };
}
function UpdateOrder(order, idorder) {
  return (dispatch) => {
    dispatch(FunctionService.request(ordersConstants.UPDATE_ORDER_REQUEST));
    RequestService.PatchRequest("stores/orders/" + idorder + "/", order, {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(res.data, ordersConstants.UPDATE_ORDER_SUCCESS)
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(err, ordersConstants.UPDATE_ORDER_FAILURE)
        );
      });
  };
}
function UpdateStaOrder(order, idorder) {
  return (dispatch) => {
    dispatch(
      FunctionService.request(ordersConstants.UPDATE_STATUS_ORDER_REQUEST)
    );
    RequestService.PatchRequest("shared/status/" + idorder + "/", order, {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(
            res.data,
            ordersConstants.UPDATE_STATUS_ORDER_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err,
            ordersConstants.UPDATE_STATUS_ORDER_FAILURE
          )
        );
      });
  };
}

function ExportOrder(filter, fileEx, isTempOrder) {
  return (dispatch) => {
    dispatch(FunctionService.request(ordersConstants.EXPORT_ORDER_REQUEST));
    if (!isTempOrder) {
      const url = `stores/orders/export_orders/?all=true&type=${fileEx}${filter.replace(
        "?",
        "&"
      )}&lang=${lang}`;
      RequestService.PostRequest(
        url,
        {},
        {
          Authorization: "Token " + token,
        },
        { responseType: "blob", isExportFromServer: true }
      )
        .then((res) => {
          dispatch(
            FunctionService.succes(
              {
                file: res.data,
                fileType: fileEx,
              },
              ordersConstants.EXPORT_ORDER_SUCCESS
            )
          );
        })
        .catch((err) => {
          dispatch(
            FunctionService.failure(err, ordersConstants.EXPORT_ORDER_FAILURE)
          );
        });
    } else {
      const url = `confirmation/temp_order/?pagination=false${filter.replace(
        "?",
        "&"
      )}&lang=${lang}`;
      RequestService.GetRequest(url, {
        Authorization: "Token " + token,
      })
        .then((res) => {
          dispatch(
            FunctionService.succes(
              {
                data: { list: res.data.results },
                fileType: fileEx,
              },
              ordersConstants.EXPORT_ORDER_SUCCESS
            )
          );
        })
        .catch((err) => {
          dispatch(
            FunctionService.failure(err, ordersConstants.EXPORT_ORDER_FAILURE)
          );
        });
    }
  };
}

function GetUnfOrders(filter) {
  return (dispatch) => {
    dispatch(FunctionService.request(ordersConstants.GET_UNF_ORDER_REQUEST));
    const [approvedFilter, cancelledFilter, callbackFilter, postponedFilter] =
      getUnfCountFilters(filter);
    const urls = [
      `confirmation/temp_order/${filter}`,
      `confirmation/temp_order/count/${approvedFilter}`,
      `confirmation/temp_order/count/${cancelledFilter}`,
      `confirmation/temp_order/count/${callbackFilter}`,
      `confirmation/temp_order/count/${postponedFilter}`,
    ];
    RequestService.GetAllRequest(urls, {
      Authorization: "Token " + token,
    })
      .then((res) => {
        const ressuc = res[0].data;
        const statusCounts = {
          approved_order: res[1].data,
          canceled_orders: res[2].data,
          callback_count: res[3].data,
          postponed_count: res[4].data,
        };
        dispatch(
          FunctionService.succes(
            { ressuc, filter, statusCounts },
            ordersConstants.GET_UNF_ORDER_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err.response,
            ordersConstants.GET_UNF_ORDER_FAILURE
          )
        );
      });
  };
};

function GetTempOrder(id) {
  return (dispatch) => {
    dispatch(FunctionService.request(ordersConstants.GET_TEMP_ORDER_BY_ID_REQUEST));
    const url = `stores/temp_orders/${id}/?version=2`;
    RequestService.GetRequest(url, {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(
            res.data,
            ordersConstants.GET_TEMP_ORDER_BY_ID_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err.response,
            ordersConstants.GET_TEMP_ORDER_BY_ID_FAILURE
          )
        );
      });
  };
}

function GetAllUnfOrders() {
  return (dispatch) => {
    dispatch(FunctionService.request(ordersConstants.GET_UNF_ORDER_REQUEST));
    const url = `stores/temp_orders/?pagination=false&status=0`;
    RequestService.GetRequest(url, {
      Authorization: "Token " + token,
    })
      .then((res) => {
        const ressuc = res.data;
        dispatch(
          FunctionService.succes(
            { ressuc },
            ordersConstants.GET_UNF_ORDER_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err.response,
            ordersConstants.GET_UNF_ORDER_FAILURE
          )
        );
      });
  };
}

function GetUnfOrderDetails(idprod) {
  return (dispatch) => {
    dispatch(FunctionService.request(ordersConstants.GET_UNF_ORDER_REQUEST));
    const url = `stores/temp_orders/?display_id=${idprod}`;
    RequestService.GetRequest(url, {
      Authorization: "Token " + token,
    })
      .then((res) => {
        const ressuc = res.data;
        dispatch(
          FunctionService.succes(
            { ressuc },
            ordersConstants.GET_UNF_ORDER_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err.response,
            ordersConstants.GET_UNF_ORDER_FAILURE
          )
        );
      });
  };
}

function GetUnfOrderTimeline(id) {
  return (dispatch) => {
    dispatch(
      FunctionService.request(ordersConstants.GET_UNF_ORDER_TIMELINE_REQUEST)
    );
    const url = `shop/order/history/${id}/`;
    RequestService.GetRequest(url, {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(
            res.data.results,
            ordersConstants.GET_UNF_ORDER_TIMELINE_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err.response,
            ordersConstants.GET_UNF_ORDER_TIMELINE_FAILURE
          )
        );
      });
  };
}

function UpdateUnfOrder(order, dataorder) {
  return (dispatch) => {
    dispatch(FunctionService.request(ordersConstants.UPDATE_UNF_ORDER_REQUEST));
    let url = "stores/temp_orders/" + dataorder + "/";
    RequestService.PutRequest(url, order, { Authorization: "Token " + token })
      .then((res) => {
        dispatch(
          FunctionService.succes(
            res.data,
            ordersConstants.UPDATE_UNF_ORDER_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(err, ordersConstants.UPDATE_UNF_ORDER_FAILURE)
        );
      });
  };
};

function ClearUpdateUnfOrderData() {
  return (dispatch) => dispatch(FunctionService.request(ordersConstants.CLEAR_UPDATE_UNF_ORDER));
}
function GetUnfOrdersCount() {
  return (dispatch) => {
    dispatch(
      FunctionService.request(ordersConstants.GET_UNF_ORDER_COUNT_REQUEST)
    );
    RequestService.GetRequest("stores/temp_orders/count_orders/", {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(
            res.data,
            ordersConstants.GET_UNF_ORDER_COUNT_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err.response,
            ordersConstants.GET_UNF_ORDER_COUNT_FAILURE
          )
        );
      });
  };
}
function GetOrders(filter, persistFilter = true) {
  const query_string = filter && filter !== "" ? `?${filter}&version=2`: `?version=2`;
  return (dispatch) => {
    dispatch(FunctionService.request(ordersConstants.GET_ORDER_REQUEST));
    RequestService.GetRequest(`stores/orders/${query_string}`, {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(
            { ressuc: res.data, filter: persistFilter ? filter : "" },
            ordersConstants.GET_ORDER_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err.response,
            ordersConstants.GET_ORDER_FAILURE
          )
        );
      });
  };
}
function GetOrderDetail(filter, persistFilter = true) {
  return (dispatch) => {
    dispatch(FunctionService.request(ordersConstants.GET_ORDER_DETAIL_REQUEST));
    RequestService.GetRequest("stores/orders/" + filter, {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(
            { ressuc: res.data, filter: persistFilter ? filter : "" },
            ordersConstants.GET_ORDER_DETAIL_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err.response,
            ordersConstants.GET_ORDER_DETAIL_FAILURE
          )
        );
      });
  };
};

function GetOrder(orderId) {
  return (dispatch) => {
    dispatch(FunctionService.request(ordersConstants.GET_ORDER_BY_ID_REQUEST));
    RequestService.GetRequest("stores/orders/" + orderId + "/", {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(
            res.data,
            ordersConstants.GET_ORDER_BY_ID_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err.response,
            ordersConstants.GET_ORDER_BY_ID_FAILURE
          )
        );
      });
  };
}
function GetOrderHistory(filter) {
  return (dispatch) => {
    dispatch(
      FunctionService.request(ordersConstants.GET_ORDER_HISTORY_REQUEST)
    );
    RequestService.GetRequest("stores/history_order/" + filter, {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(
            { ressuc: res.data },
            ordersConstants.GET_ORDER_HISTORY_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err.response,
            ordersConstants.GET_ORDER_HISTORY_FAILURE
          )
        );
      });
  };
}
function GetConfirmationOrders(filter, persistFilter = true) {
  return (dispatch) => {
    dispatch(FunctionService.request(ordersConstants.GET_CONF_ORDER_REQUEST));
    RequestService.GetRequest("confirmation/orders_2b_confirmed/" + filter, {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(
            { ressuc: res.data, filter: persistFilter ? filter : "" },
            ordersConstants.GET_CONF_ORDER_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err.response,
            ordersConstants.GET_CONF_ORDER_FAILURE
          )
        );
      });
  };
}
function GetConfirmationSummary(filter) {
  return (dispatch) => {
    dispatch(FunctionService.request(ordersConstants.GET_CONF_SUMMARY_REQUEST));
    RequestService.GetRequest("confirmation/reports/" + filter, {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(
            { ressuc: res.data, filter: filter },
            ordersConstants.GET_CONF_SUMMARY_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err.response,
            ordersConstants.GET_CONF_SUMMARY_FAILURE
          )
        );
      });
  };
}

function ClearOrderStore() {
  return (dispatch) => {
    dispatch({ type: "CLEAR" });
  };
}

function GetHooksEvents() {
  return (dispatch) => {
    dispatch(FunctionService.request(userConstants.GET_TYPES_WEBHOOK_REQUEST));
    RequestService.GetRequest("stores/hooks/types/", {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(
            res.data,
            userConstants.GET_TYPES_WEBHOOK_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err.response,
            userConstants.GET_TYPES_WEBHOOK_FAILURE
          )
        );
      });
  };
}

function GetWebHooks() {
  return (dispatch) => {
    dispatch(FunctionService.request(userConstants.GET_WEBHOOK_REQUEST));
    RequestService.GetRequest("stores/hooks/costume/", {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(
            { ressuc: res.data },
            userConstants.GET_WEBHOOK_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err.response,
            userConstants.GET_WEBHOOK_FAILURE
          )
        );
      });
  };
}

function DeleteWebHooks(id) {
  return (dispatch) => {
    dispatch(FunctionService.request(userConstants.DELETE_WEBHOOK_REQUEST));
    RequestService.DeleteRequest("stores/hooks/costume/" + id, {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(
            { ressuc: res.data },
            userConstants.DELETE_WEBHOOK_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err.response,
            userConstants.DELETE_WEBHOOK_FAILURE
          )
        );
      });
  };
}

function PostWebHooks(endpoint, trigger_type_id) {
  return (dispatch) => {
    dispatch(FunctionService.request(userConstants.POST_WEBHOOK_REQUEST));
    RequestService.PostRequest(
      "stores/hooks/costume/",
      { endpoint, trigger_type_id },
      {
        Authorization: "Token " + token,
      }
    )
      .then((res) => {
        dispatch(
          FunctionService.succes(res, userConstants.POST_WEBHOOK_SUCCESS)
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(err, userConstants.POST_WEBHOOK_FAILURE)
        );
      });
  };
}

function TestWebHooks(endpoint) {
  return (dispatch) => {
    dispatch(FunctionService.request(userConstants.TEST_WEBHOOK_REQUEST));
    RequestService.PostRequest(
      "stores/hooks/test/request/",
      { endpoint },
      {
        Authorization: "Token " + token,
      }
    )
      .then((res) => {
        dispatch(
          FunctionService.succes(res, userConstants.TEST_WEBHOOK_SUCCESS)
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(err, userConstants.TEST_WEBHOOK_FAILURE)
        );
      });
  };
}

function ImportOdrProds(formdata, extension) {
  return (dispatch) => {
    dispatch(FunctionService.request(ordersConstants.IMPORT_ORDPRDS_REQUEST));
    RequestService.PostRequest(
      extension === "xlsx"
        ? "stores/orders/bulk_excel/"
        : "stores/orders/bulk_csv/",
      formdata,
      {
        Authorization: "Token " + token,
        "content-type": "multipart/form-data",
      },
      { isExportFromServer: true }
    )
      .then((res) => {
        dispatch(
          FunctionService.succes(
            res.data,
            ordersConstants.IMPORT_ORDPRDS_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(err, ordersConstants.IMPORT_ORDPRDS_FAILURE)
        );
      });
  };
}

function GenerateOrdersBordureauStarter(orders_ids, all_created) {
  return (dispatch) => {
    dispatch(FunctionService.request(userConstants.POST_BORDEREAU_REQUEST));
    RequestService.PostRequest(
      "delivery/starter/starter_bordureau/",
      { orders_ids, all_created },
      {
        Authorization: "Token " + token,
      },
      { responseType: "blob", isExportFromServer: true }
    )
      .then((response) => {
        const file = new Blob([response.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        dispatch(
          FunctionService.succes(file, userConstants.POST_BORDEREAU_SUCCESS)
        );
      })

      .catch((err) => {
        dispatch(
          FunctionService.failure(err, userConstants.POST_BORDEREAU_FAILURE)
        );
      });
  };
}

function GetCurrentorders() {
  return (dispatch) => {
    dispatch(
      FunctionService.request(ordersConstants.GET_CURRENT_ORDERS_REQUEST)
    );
    RequestService.GetRequest("stores/orders/current_orders/", {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(
            res.data,
            ordersConstants.GET_CURRENT_ORDERS_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err.response,
            ordersConstants.GET_CURRENT_ORDERS_FAILURE
          )
        );
      });
  };
}

function EditCallbackInUnfOrder(orderId, isCallback, successCallback) {
  return (dispatch) => {
    dispatch(
      FunctionService.request(ordersConstants.UPDATE_CALLBACK_UNF_ORDER_REQUEST)
    );
    const status = isCallback ? 1 : 0;
    RequestService.PostRequest(
      `confirmation/temp_order/${orderId}/callback/`,
      { status },
      {
        Authorization: "Token " + token,
      }
    )
      .then(() => {
        dispatch(
          FunctionService.succes(
            {
              orderId,
              isCallback,
            },
            ordersConstants.UPDATE_CALLBACK_UNF_ORDER_SUCCESS
          )
        );
        if (successCallback) successCallback();
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err,
            ordersConstants.UPDATE_CALLBACK_UNF_ORDER_FAILURE
          )
        );
      });
  };
}

function EditPostponedInUnfOrder(orderId, postponed_to) {
  return (dispatch) => {
    const payload = postponed_to ? { postponed_to, status: 1 } : { status: 0 };
    dispatch(
      FunctionService.request(
        ordersConstants.UPDATE_POSTPONED_UNF_ORDER_REQUEST
      )
    );
    RequestService.PostRequest(
      `confirmation/temp_order/${orderId}/postpone/`,
      payload,
      {
        Authorization: "Token " + token,
      }
    )
      .then(() => {
        dispatch(
          FunctionService.succes(
            { orderId, postponed_to },
            ordersConstants.UPDATE_POSTPONED_UNF_ORDER_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err,
            ordersConstants.UPDATE_POSTPONED_UNF_ORDER_FAILURE
          )
        );
      });
  };
}
