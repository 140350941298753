export const pickupsConstants = {
  ADD_PICKUP_SUCCESS: "ADD_PICKUP_SUCCESS",
  ADD_PICKUP_FAILURE: "ADD_PICKUP_FAILURE",
  ADD_PICKUP_REQUEST: "ADD_PICKUP_REQUEST",

  IMPORT_PICKUP_SUCCESS: "IMPORT_PICKUP_SUCCESS",
  IMPORT_PICKUP_FAILURE: "IMPORT_PICKUP_FAILURE",
  IMPORT_PICKUP_REQUEST: "IMPORT_PICKUP_REQUEST",

  UPDATE_PICKUP_SUCCESS: "UPDATE_PICKUP_SUCCESS",
  UPDATE_PICKUP_FAILURE: "UPDATE_PICKUP_FAILURE",
  UPDATE_PICKUP_REQUEST: "UPDATE_PICKUP_REQUEST",

  UPDATE_STATUS_PICKUP_SUCCESS: "UPDATE_STATUS_PICKUP_SUCCESS",
  UPDATE_STATUS_PICKUP_FAILURE: "UPDATE_STATUS_PICKUP_FAILURE",
  UPDATE_STATUS_PICKUP_REQUEST: "UPDATE_STATUS_PICKUP_REQUEST",

  GET_PICKUP_SUCCESS: "GET_PICKUP_SUCCESS",
  GET_PICKUP_FAILURE: "GET_PICKUP_FAILURE",
  GET_PICKUP_REQUEST: "GET_PICKUP_REQUEST",

  GET_POSSIBLE_ORDERS_SUCCESS: "GET_POSSIBLE_ORDERS_SUCCESS",
  GET_POSSIBLE_ORDERS_FAILURE: "GET_POSSIBLE_ORDERS_FAILURE",
  GET_POSSIBLE_ORDERS_REQUEST: "GET_POSSIBLE_ORDERS_REQUEST",

  GET_PICKUPORDERS_SUCCESS: "GET_PICKUPORDERS_SUCCESS",
  GET_PICKUPORDERS_FAILURE: "GET_PICKUPORDERS_FAILURE",
  GET_PICKUPORDERS_REQUEST: "GET_PICKUPORDERS_REQUEST",

  POST_BORDEREAU_PICKUP_REQUEST: "POST_BORDEREAU_PICKUP_REQUEST",
  POST_BORDEREAU_PICKUP_SUCCESS: "POST_BORDEREAU_PICKUP_SUCCESS",
  POST_BORDEREAU_PICKUP_FAILURE: "POST_BORDEREAU_PICKUP_FAILURE",

  CLEAR: "CLEAR",
  CLEAR_PICKUP_ADD_UPDATE: "CLEAR_PICKUP_ADD_UPDATE"
};
